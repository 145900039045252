.analyticalReportRFMAnalysis__topSection.analyticalReportRFMAnalysis__topSection__gp{
    padding: 20px 0;
}

.analyticalReportChartAnalysisOne__bottomSection{
    display: flex;
    flex-wrap: wrap;
    margin: 2rem 0 2rem 0;

    .analyticalReportChartAnalysisOne__heading{
        display: flex;
        flex-direction: column;
        margin: 0 0 1rem;
        width: 100%;

        h2{
            font-size: 1.2rem;
            font-weight: 600;
            line-height: 1.2;
            color: #000000;
            letter-spacing: 0px;
            margin: 0;
        }
    }

    .analyticalReportChartAnalysisOne__leftSection{
        width: calc(50% - 10px);
        margin-right: 20px;

        .analyticalReportChartAnalysisOne__box{
            display: flex;
            flex-direction: column;
            border-radius: 1.5rem;
            margin-top: 1rem;

            .analyticalReportChartAnalysisOne__header{
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 1px solid #262c30;
                border-bottom: none;
                padding: 1rem;
                border-top-left-radius: 1.5rem;
                border-top-right-radius: 1.5rem;

                .analyticalReportChartAnalysisOne__left{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .analyticalReportChartAnalysisOne__image{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 2.5rem;
                        height: 2.5rem;
                        background-color: #d9eeff;
                        border-radius: 0.75rem;
                        margin-right: 0.5rem;

                        img{
                            width: 50%;
                        }
                    }

                    .analyticalReportChartAnalysisOne__details{
                        display: flex;
                        flex-direction: column;

                        h3{   
                            font-size: 1rem;
                            font-weight: 700;
                            line-height: 1.2;
                            color: #000000;
                            margin: 0;
                        }
                    }
                }
            }

            .analyticalReportChartAnalysisOne__reportSection{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border: 1px solid #262c30;
                padding: 1.5rem 1rem;
                border-bottom-left-radius: 1.5rem;
                border-bottom-right-radius: 1.5rem;

                h3{
                    font-size: 1.8rem;
                    font-weight: 700;
                    line-height: 1.2;
                    color: #9a00c0;
                    margin: 0 0 0.25rem;
                }

                p{
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.2;
                    color: #000000;
                    margin: 0;
                }
            }
        }
    }

    .analyticalReportChartAnalysisOne__rightSection{
        width: calc(50% - 10px);

        .analyticalReportChartAnalysisOne__box{
            display: flex;
            flex-direction: column;
            border-radius: 1.5rem;
            margin-top: 1rem;

            .analyticalReportChartAnalysisOne__header{
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 1px solid #262c30;
                border-bottom: none;
                padding: 1rem;
                border-top-left-radius: 1.5rem;
                border-top-right-radius: 1.5rem;

                .analyticalReportChartAnalysisOne__left{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    .analyticalReportChartAnalysisOne__image{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 2.5rem;
                        height: 2.5rem;
                        background-color: #d9eeff;
                        border-radius: 0.75rem;
                        margin-right: 0.5rem;

                        img{
                            width: 50%;
                        }
                    }

                    .analyticalReportChartAnalysisOne__details{
                        display: flex;
                        flex-direction: column;

                        h3{
                            font-size: 1rem;
                            font-weight: 700;
                            line-height: 1.2;
                            color: #000000;
                            margin: 0;
                        }
                    }
                }

                .analyticalReportChartAnalysisOne__right{
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    .analyticalReportChartAnalysisOne__select{
                        display: flex;
                        flex-direction: column;

                        .form-control{
                            display: block;
                        }
                    }
                }
            }

            .analyticalReportChartAnalysisOne__chartSection{
                display: flex;
                flex-direction: column;
                border: 1px solid #262c30;
                padding: 5rem 1rem;
                border-bottom-left-radius: 1.5rem;
                border-bottom-right-radius: 1.5rem;

                .analyticalReportChartAnalysisOne__barChartReport{
                    width: 100%;
                    height: 100%;

                    canvas{
                        width: 100% !important;
                        height: 100% !important;
                    }
                }
            }
        }
    }
}

.analyticalReportChartAnalysisTwo__rightSection.analyticalReportChartAnalysisTwo__rightSection__gp{
    justify-content: flex-start !important;


    .businessGoalsSelection__bottomSection{
        display: flex;
        flex-direction: column;
        padding: 0;

        h5{
            text-align: left;
            color: #000;
            margin-bottom: 1rem;
            font-weight: 600;
        }

        .businessGoalsSelection__accordionItem{
            display: flex;
            flex-direction: column;
            padding: 0;
            border: 1px solid transparent;
            border-radius: 0.75rem;
            background-color: transparent;
            margin: 0 0 0.7rem;
            width: calc(100% - 10px);

            .businessGoalsSelection__accordionHeading{
                display: flex;
                flex-direction: column;
                padding: 0;
                border: none;
                border-radius: 0;
                background-color: transparent;

                .businessGoalsSelection__accordionButton{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 0.7rem;
                    font-weight: 700;
                    line-height: 1.2;
                    color: #000000;
                    border: none;
                    text-align: left;
                    padding: 15px 15px 15px 30px;
                    position: relative;
                    transition: all 0.5s ease;
                    border-radius: 5px;
                    background-color: #add8fb;

                    input{
                        margin-left: -10px;
                        margin-right: 15px;
                    }
                }
            }
        }
    }
}

.businessGoalsSelection__buttonSection.businessGoalsSelection__buttonSectiongp{
    .businessGoalsSelection__buttonSectionLeft{
        a{color: #000;}
    }
    .businessGoalsSelection__buttonSectionRight{
        .btn{
            padding: 0.5rem 0.8rem;
            font-size: 0.65rem; 

            &:last-child {
                margin-left: 0.5rem;
            }
        }
    }
}

.analyticalReportChartAnalysisOne__chartSection{
    display: flex;
    flex-direction: column;
    border: 1px solid #262c30;
    padding: 5rem 1rem;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;

    .analyticalReportChartAnalysisOne__barChartReport{
        width: 100%;
        height: 100%;

        canvas{
            width: 100% !important;
            height: 100% !important;
        }
    
    }
}
