///* ----------- Default Styling Start --------------- */

$primary-fontFamily: "Montserrat", sans-serif;
$primary-fontWeight: 400;
$primary-lineHeight: 1.4;
$primary-fontColor: #6e6b7b;
$primary-color: #2056a5;
$primary-hover: #1b427c;
$primary-bgColor: #fafafa;
$color-black: #000000;
$color-white: #ffffff;
$mainbg-color: #e3f2fd;

/// Viewport
$desktop_xxxl: 1920px;
$desktop_xxl: 1650px;
$desktop_xl: 1440px;
$laptop: 1366px;
$desktop: 1280px;
$desktopSmall: 1024px;
$tabletSmall: 768px;
$mobileBig: 767px;
$mobile: 480px;
$mobileSmall: 360px;

/// Progressbar Color & Speed
$progressbar-color1: #4071eb;
$progressbar-color2: #56cfeb;
$progressbar-speed: 2.5s;

$inp_radius: 4px;
$inp_height: 38px;

///* ----------- Default Styling End ----------------- */

.dashboard {
    $dashboard: ".dashboard";

    display: flex;
    flex-direction: row;

    // position: relative;
    // z-index: 0;

    &_dark{
        &__sidebar {
            position: fixed;
            top: 0;
            left: 0;
            background: #262c30;
            color: $color-white;
            height: 100%;
            min-height: 100vh;
            width: 3.5rem;
            padding: 0;
            margin: 0;
            // overflow: auto;
            box-shadow: 0 0.2rem 0.2rem 0 rgb(0, 0, 0, 25%);
            transition: all 0.5s ease;
            z-index: 3;
    
            @media (max-width: $mobileBig) {
            }
    
            #{$dashboard}__topSection {
                display: flex;
                align-items: center;
                justify-content: space-between;
    
                padding: 0.75rem;
    
                #{$dashboard}__logoBox {
                    display: none;
                    height: 2rem;
                    filter: brightness(0) invert(1);
    
                    img {
                        width: auto;
                        height: 100%;
                    }
                }
    
                #{$dashboard}__menuBox {
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    width: 2rem;
    
                    span {
                        display: block;
                        cursor: pointer;
    
                        img {
                            width: 100%;
    
                            &.inside {
                                display: none;
                            }
    
                            &.outside {
                                display: block;
                            }
                        }
                    }
                }
            }
    
            #{$dashboard}__menuMain {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
    
                height: calc(100% - 3.5rem);
    
                #{$dashboard}__menuTop {
                    display: flex;
                    flex-direction: column;
    
                    ul {
                        margin: 0;
                        padding: 0;
    
                        li {
                            display: flex;
                            align-items: center;
                            justify-content: center;
    
                            position: relative;
                            z-index: 0;
                            padding: 0.375rem 0.75rem;
    
                            .link {
                                display: flex;
                                align-items: center;
                                justify-content: center;
    
                                color: $color-white;
                                padding: 0.375rem;
                                transition: all 0.5s;
    
                                border-radius: 0.5rem;
                                background: transparent;
                                border: none;
                                width: 100%;
                                text-transform: capitalize;
    
                                .icon {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
    
                                    width: 1.25rem;
                                    height: 1.25rem;
                                    margin: 0;
    
                                    img {
                                        width: 100%;
                                        filter: brightness(0) invert(1);
    
                                        &.offHover {
                                            display: block;
                                        }
    
                                        &.onHover {
                                            display: none;
                                        }
                                    }
                                }
    
                                .link_text {
                                    display: none;
    
                                    font-size: 0.8rem;
                                    margin-left: 0.5rem;
                                }
    
                                .link_icon{
                                    display: none;
                                    position: absolute;
                                    right: 20px
                                }
    
                                &:hover,
                                &.active {
                                    background: $color-white;
                                    color: $color-black;
    
                                    .icon {
                                        img {

                                            filter: brightness(0);
                                            &.offHover {
                                                display: none;
                                            }
    
                                            &.onHover {
                                                display: block;
                                            }
                                        }
                                    }
    
                                    .link_text {
                                        font-weight: 600;
                                    }
                                }
                            }
    
                            .childrenDropdown {
                                position: absolute;
                                top: 0;
                                left: 100%;
                                background-color:#262c30;
                                width: auto;
                                min-width: 10.05rem;
    
                                // height: auto;
                                // opacity: 1;
                                // visibility: visible;
    
                                // &.show {
                                // 	height: auto;
                                // 	opacity: 1;
                                // 	visibility: visible;
                                // }
    
                                // .closeChildrenMenu {}
    
                                li {
                                    display: block;
                                    padding: 0.5rem !important;
                                    // border-bottom: 0.05rem solid $primary-bgColor;
    
                                    &:last-child {
                                        border-bottom: none;
                                    }
    
                                    a {
                                        display: block;
                                        font-size: 0.8rem;
                                        color: $color-white;
                                        transition: all 0.5s;
    
                                        border-radius: 0.5rem;
                                        padding: 0.5rem;
                                        white-space: nowrap;
    
                                        &:hover,
                                        &.active {
                                            background: $color-white;
                                            color: $color-black;
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                #{$dashboard}__menuBottom {
                    display: flex;
                    flex-direction: column;
    
                    ul {
                        margin: 0;
                        padding: 0;
    
                        li {
                            display: flex;
                            align-items: center;
                            justify-content: center;
    
                            position: relative;
                            z-index: 0;
                            padding: 0.375rem 0.75rem;
    
                            .link {
                                display: flex;
                                align-items: center;
                                justify-content: center;
    
                                color: $color-white;
                                padding: 0.375rem;
                                transition: all 0.5s;
    
                                border-radius: 0.5rem;
                                background: transparent;
                                border: none;
                                width: 100%;
                                text-transform: capitalize;
    
                                .icon {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
    
                                    width: 1rem;
                                    height: 1rem;
                                    margin: 0;
    
                                    img {
                                        width: 100%;
                                        filter: brightness(0) invert(1);
    
                                        &.offHover {
                                            display: block;
                                        }
    
                                        &.onHover {
                                            display: none;
                                        }
                                    }
                                }
    
                                .link_text {
                                    display: none;
    
                                    font-size: 0.8rem;
                                    margin-left: 0.5rem;
                                }
                                
                                .link_icon{
                                    display: none;
                                    position: absolute;
                                    right: 20px
                                }
    
                                &:hover,
                                &.active {
                                    background: $color-white;
                                    color: $color-black;
    
                                    .icon {
                                        img {

                                            filter: brightness(0);
                                            
                                            &.offHover {
                                                display: none;
                                            }
    
                                            &.onHover {
                                                display: block;
                                            }
                                        }
                                    }
                                }
                            }
    
                            .childrenDropdown {
                                position: absolute;
                                top: 50%;
                                left: 100%;
                                width: auto;
                                min-width: 10.05rem;
                                background-color: $color-white;
                                transform: translateY(-70%);
                                padding: 0.25rem 0;
    
                                // height: auto;
                                // opacity: 1;
                                // visibility: visible;
    
                                // &.show {
                                // 	height: auto;
                                // 	opacity: 1;
                                // 	visibility: visible;
                                // }
    
                                // .closeChildrenMenu {}
    
                                li {
                                    display: block;
                                    padding: 0.25rem 0.5rem !important;
                                    border-bottom: 0.05rem solid $primary-bgColor;
    
                                    &:last-child {
                                        border-bottom: none;
                                    }
    
                                    a {
                                        display: block;
                                        font-size: 0.8rem;
                                        color: $color-white;
                                        transition: all 0.5s;
    
                                        border-radius: 0.5rem;
                                        padding: 0.5rem;
                                        white-space: nowrap;
    
                                        &:hover,
                                        // &.active {
                                        //     background: #d9eeff;
                                        //     color: $primary-color;
                                        //     font-weight: 600;
                                        // }
    
                                        &.activee{
                                            background: $color-white;
                                            color: $color-black;
                                            font-weight: 600;
                                        }
                                    }
                                }
    
                                ul {
                                    position: absolute;
                                    top: 0%;
                                    left: 100%;
                                    width: auto;
                                    min-width: 10.05rem;
                                    background-color: #ffffff;
                                    transform: translateY(-50%);
                                    padding: 0.25rem 0;
    
                                    li {
                                        //display: flex;
                                        //align-items: center;
                                        //justify-content: center;
                                        position: relative;
                                        z-index: 0;
                                        padding: 0.375rem 0.75rem;
                                        display: block;
                                        text-align: left;
    
                                        a {
                                            display: block;
                                            font-size: 0.8rem;
                                            color: $color-white;
                                            transition: all 0.5s;
                                            border-radius: 0.5rem;
                                            padding: 0.5rem;
                                            white-space: nowrap;
                                        }
                                    }
                                }
                            }
    
                            .childrenDropdown.childrenDropdown_2 {
                                top: -33px;
                            }
                        }
                    }
                }
            }
        }

        &__mainPage {
            width: calc(100% - 3.5rem);
            margin-left: 3.5rem;
            transition: all 0.5s ease;
            background-color: $color-white;
            //background-color: #F5F5F5;
            min-height: 100vh;
            // position: relative;
            // z-index: 1;
    
            // @media (max-width: $mobileBig) {
            // 	width: calc(100% - 3.5rem);
            // 	margin-left: 3.5rem;
            // }
    
            #{$dashboard}__headerSection {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: fixed;
                top: 0;
                left: 3.5rem;
                width: calc(100% - 3.5rem);
                z-index: 2;
                background-color: $primary-bgColor;
                padding: 0.85rem 0.75rem;
                transition: all 0.5s ease;
    
                // @media (max-width: $mobileBig) {
                // 	width: calc(100% - 3.5rem);
                // 	left: 3.5rem;
                // 	padding-left: 1rem;
                // 	padding-right: 1rem;
                // }
    
                // @media (max-width: $mobile - 1) {
                // 	padding-left: 0.75rem;
                // 	padding-right: 0.75rem;
                // }
    
                #{$dashboard}__heading {
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    width: 70%;
    
                    @media (max-width: $mobileBig) {
                        justify-content: flex-start;
                        width: 60%;
                    }
    
                    h3 {
                        font-size: 1.2rem;
                        font-weight: 600;
                        line-height: 1.5;
                        color: $color-black;
                        margin: 0;
    
                        // @media (max-width: $mobileBig) {
                        // 	font-size: 1rem;
                        // }
    
                        // @media (max-width: $mobile - 1) {
                        // 	font-size: 0.8rem;
                        // }
                    }
                }
    
                #{$dashboard}__username {
                    display: flex;
                    align-items: center;
                    justify-content: right;
                    width: 30%;
                    padding-right: 2rem;
    
                    @media screen and (max-width: $mobileBig) {
                        width: 40%;
                        padding-right: 0;
                    }
    
                    h5 {
                        margin-bottom: 0;
                        color: $color-black;
                        font-size: 1rem;
                        overflow: hidden;
                        white-space: pre;
                        text-overflow: ellipsis;
    
                        @media screen and (max-width: $mobileBig) {
                            font-size: 0.8rem;
                        }
    
                        span {
                            color: #0d6efd;
                        }
                    }
                }
    
                // #{$dashboard}__rightSection {
                // 	display: flex;
                // 	align-items: center;
                // 	justify-content: flex-end;
    
                // 	position: absolute;
                // 	top: 50%;
                // 	right: 1.5rem;
                // 	transform: translateY(-50%);
                // 	width: 4rem;
    
                // 	@media (max-width: $mobileBig) {
                // 		width: 3.25rem;
                // 	}
    
                // 	> ul {
                // 		display: flex;
                // 		align-items: center;
                // 		justify-content: flex-end;
    
                // 		margin: 0;
                // 		padding: 0;
    
                // 		> li {
                // 			display: block;
                // 			margin-left: 0.5rem;
                // 			position: relative;
    
                // 			&:first-child {
                // 				margin-left: 0;
                // 			}
    
                // 			@media (max-width: $mobileBig) {
                // 				margin-left: 0.25rem;
                // 			}
    
                // 			.notifications {
                // 				display: block;
                // 				cursor: pointer;
                // 				width: 1.75rem;
                // 				height: 1.75rem;
    
                // 				@media (max-width: $mobileBig) {
                // 					width: 1.5rem;
                // 					height: 1.5rem;
                // 				}
    
                // 				@media (max-width: $mobile - 1) {
                // 					width: 1.25rem;
                // 					height: 1.25rem;
                // 				}
    
                // 				img {
                // 					width: 100%;
                // 				}
    
                // 				&.active {
                // 					position: relative;
                // 					z-index: 0;
    
                // 					&::after {
                // 						content: "";
                // 						position: absolute;
                // 						top: -0.1rem;
                // 						right: -0.1rem;
                // 						width: 0.85rem;
                // 						height: 0.85rem;
                // 						border-radius: 50%;
                // 						border: 0.15rem solid $color-white;
                // 						background-color: red;
                // 					}
                // 				}
                // 			}
    
                // 			.administrator {
                // 				$administrator: ".administrator";
    
                // 				position: relative;
                // 				z-index: 0;
                // 				display: block;
                // 				cursor: pointer;
                // 				width: 1.75rem;
                // 				height: 1.75rem;
    
                // 				@media (max-width: $mobileBig) {
                // 					width: 1.5rem;
                // 					height: 1.5rem;
                // 				}
    
                // 				@media (max-width: $mobile - 1) {
                // 					width: 1.25rem;
                // 					height: 1.25rem;
                // 				}
    
                // 				#{$administrator}__currentProfilePic {
                // 					display: block;
    
                // 					img {
                // 						width: 100%;
                // 					}
                // 				}
    
                // 				#{$administrator}__updateProfilePic {
                // 					$profileSize: 1rem;
    
                // 					position: absolute;
                // 					bottom: 0;
                // 					right: 0;
                // 					width: $profileSize;
                // 					height: $profileSize;
                // 					transform: translate(0.25rem, 0.25rem);
                // 					z-index: 1;
    
                // 					#{$administrator}__input {
                // 						position: absolute;
                // 						top: 0;
                // 						left: 0;
                // 						opacity: 0;
                // 						width: $profileSize;
                // 						height: $profileSize;
                // 						z-index: 1;
                // 						cursor: pointer;
                // 					}
    
                // 					#{$administrator}__icon {
                // 						display: flex;
                // 						align-items: center;
                // 						justify-content: center;
    
                // 						position: relative;
                // 						z-index: 0;
                // 						width: $profileSize;
                // 						height: $profileSize;
                // 						border-radius: 50%;
                // 						background-color: $color-white;
    
                // 						i {
                // 							font-size: 0.6rem;
                // 							color: $color-black;
                // 						}
                // 					}
                // 				}
                // 			}
    
                // 			.notificationsBox {
                // 				$notificationsBox: ".notificationsBox";
    
                // 				display: flex;
                // 				flex-direction: column;
    
                // 				background-color: $color-white;
                // 				border: 0.05rem solid rgba(0, 0, 0, 0.175);
                // 				border-radius: 0.375rem;
                // 				position: absolute;
                // 				top: calc(100% + 1rem);
                // 				left: auto;
                // 				right: 0;
                // 				width: auto;
                // 				min-width: 21rem;
                // 				padding: 0;
                // 				transform-origin: 0 0 0;
                // 				z-index: 999;
    
                // 				opacity: 0;
                // 				visibility: hidden;
                // 				transform: scaleY(0);
    
                // 				&.show {
                // 					opacity: 1;
                // 					visibility: visible;
                // 					transform: scaleY(1);
                // 				}
    
                // 				@media (max-width: $mobileBig) {
                // 					min-width: 14rem;
                // 				}
    
                // 				@media (max-width: $mobile - 1) {
                // 					right: -2rem;
                // 				}
    
                // 				#{$notificationsBox}__header {
                // 					display: flex;
                // 					align-items: center;
                // 					justify-content: space-between;
    
                // 					padding: 1rem;
                // 					border-bottom: 0.05rem solid #ebe9f1;
    
                // 					@media (max-width: $mobileBig) {
                // 						padding: 0.5rem;
                // 					}
    
                // 					#{$notificationsBox}__left {
                // 						display: flex;
                // 						flex-direction: column;
    
                // 						h4 {
                // 							font-size: 0.9rem;
                // 							font-weight: 500;
                // 							line-height: 1.5;
                // 							color: #5e5873;
                // 							margin: 0;
                // 						}
                // 					}
    
                // 					#{$notificationsBox}__right {
                // 						display: flex;
                // 						flex-direction: column;
    
                // 						span {
                // 							font-size: 85%;
                // 							font-weight: 600;
                // 							line-height: 1.5;
                // 							white-space: nowrap;
                // 							background-color: rgba(115, 103, 240, 0.12);
                // 							padding: 0.25rem 0.5rem;
                // 							text-align: center;
                // 							color: #7367f0;
                // 						}
                // 					}
                // 				}
    
                // 				#{$notificationsBox}__notifications {
                // 					display: flex;
                // 					flex-direction: column;
    
                // 					ul {
                // 						display: flex;
                // 						flex-direction: column;
    
                // 						padding: 0;
                // 						margin: 0;
                // 						max-height: 12.2rem;
                // 						overflow: auto;
    
                // 						li {
                // 							display: flex;
                // 							flex-direction: column;
    
                // 							padding: 1rem;
                // 							border-bottom: 0.05rem solid #ebe9f1;
    
                // 							&:last-child {
                // 								border-bottom: 0.05rem solid #ebe9f1;
                // 							}
    
                // 							&:hover {
                // 								background: #f8f8f8;
                // 							}
    
                // 							@media (max-width: $mobileBig) {
                // 								padding: 0.5rem;
                // 							}
    
                // 							a {
                // 								display: flex;
                // 								align-items: center;
                // 								justify-content: flex-start;
    
                // 								#{$notificationsBox}__img {
                // 									display: flex;
                // 									align-items: center;
                // 									justify-content: center;
    
                // 									width: 2rem;
                // 									height: 2rem;
                // 									margin-right: 0.75rem;
    
                // 									@media (max-width: $mobileBig) {
                // 										width: 1.5rem;
                // 										height: 1.5rem;
                // 										margin-right: 0.375rem;
                // 									}
    
                // 									img {
                // 										width: 100%;
                // 										height: 100%;
                // 										object-fit: cover;
                // 									}
                // 								}
    
                // 								#{$notificationsBox}__content {
                // 									display: flex;
                // 									flex-direction: column;
    
                // 									width: calc(100% - 25.25rem);
    
                // 									@media (max-width: $mobileBig) {
                // 										width: calc(100% - 15.375rem);
                // 									}
    
                // 									h5 {
                // 										font-size: 0.8rem;
                // 										font-weight: 600;
                // 										line-height: 1.5;
                // 										color: #5e5873;
                // 										margin: 0 0 0.25rem;
    
                // 										@media (max-width: $mobileBig) {
                // 											font-size: 0.7rem;
                // 										}
                // 									}
    
                // 									p {
                // 										font-size: 0.7rem;
                // 										font-weight: 400;
                // 										line-height: 1.5;
                // 										color: #5e5873;
                // 										margin: 0;
    
                // 										@media (max-width: $mobileBig) {
                // 											font-size: 0.6rem;
                // 										}
                // 									}
                // 								}
                // 							}
                // 						}
                // 					}
                // 				}
    
                // 				#{$notificationsBox}__footer {
                // 					padding: 1rem;
    
                // 					@media (max-width: $mobileBig) {
                // 						padding: 0.5rem;
                // 					}
                // 				}
                // 			}
    
                // 			.administratorBox {
                // 				$administratorBox: ".administratorBox";
    
                // 				display: flex;
                // 				flex-direction: column;
    
                // 				background-color: $color-white;
                // 				border: 0.05rem solid rgba(0, 0, 0, 0.175);
                // 				border-radius: 0.375rem;
                // 				position: absolute;
                // 				top: calc(100% + 1rem);
                // 				left: auto;
                // 				right: 0;
                // 				width: auto;
                // 				min-width: 11rem;
                // 				padding: 0;
                // 				transform-origin: 0 0 0;
                // 				z-index: 999;
    
                // 				opacity: 0;
                // 				visibility: hidden;
                // 				transform: scaleY(0);
    
                // 				&.show {
                // 					opacity: 1;
                // 					visibility: visible;
                // 					transform: scaleY(1);
                // 				}
    
                // 				ul {
                // 					align-items: flex-start;
                // 					flex-direction: column;
    
                // 					margin: 0;
                // 					padding: 0;
    
                // 					li {
                // 						display: flex;
                // 						flex-direction: column;
    
                // 						margin: 0;
                // 						padding: 0;
                // 						width: 100%;
    
                // 						.link {
                // 							display: flex;
                // 							align-items: center;
                // 							justify-content: flex-start;
    
                // 							color: $color-black;
                // 							padding: 0.75rem 0.75rem;
                // 							transition: all 0.5s;
                // 							border-radius: 0;
    
                // 							.icon {
                // 								display: flex;
                // 								align-items: center;
                // 								justify-content: center;
    
                // 								width: 1rem;
                // 								height: 1rem;
                // 								margin: 0;
    
                // 								img {
                // 									width: 100%;
    
                // 									&.offHover {
                // 										display: block;
                // 									}
    
                // 									&.onHover {
                // 										display: none;
                // 									}
                // 								}
                // 							}
    
                // 							.link_text {
                // 								font-size: 0.8rem;
                // 								margin-left: 0.5rem;
                // 							}
    
                // 							&:hover,
                // 							&.active {
                // 								background: #d9eeff;
                // 								color: $primary-color;
    
                // 								.icon {
                // 									img {
                // 										&.offHover {
                // 											display: none;
                // 										}
    
                // 										&.onHover {
                // 											display: block;
                // 										}
                // 									}
                // 								}
                // 							}
                // 						}
                // 					}
                // 				}
                // 			}
                // 		}
                // 	}
                // }
            }
    
            #{$dashboard}__content {
                padding: 2rem;
                margin-top: 3.5rem;
    
                @media (max-width: $mobileBig) {
                    padding: 1.5rem;
                    margin-top: 3.2rem;
                }
    
                // @media (max-width: $mobile - 1) {
                // 	padding: 0.75rem;
                // }
    
                > .container-fluid {
                    padding-left: 0;
                    padding-right: 0;
                }
    
                #{$dashboard}__heading {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
    
                    margin-bottom: 2.5rem;
    
                    @media (max-width: $mobileBig) {
                        flex-direction: column;
                    }
    
                    #{$dashboard}__headingLeft {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
    
                        @media (max-width: $mobileBig) {
                            align-items: center;
                            justify-content: center;
    
                            width: 100%;
                            margin-bottom: 1.5rem;
                            text-align: center;
                        }
    
                        #{$dashboard}__headingBackLink {
                            display: flex;
                            flex-direction: column;
    
                            a {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
    
                                // width: 1.75rem;
                                height: 1.75rem;
    
                                img {
                                    width: 1.25rem;
                                    margin-right: 10px;
                                }
                            }
    
                            button {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                width: 1.75rem;
                                height: 1.75rem;
                                background-color: transparent;
                                border: none;
    
                                img {
                                    width: 1.25rem;
                                }
                            }
                        }
    
                        #{$dashboard}__headingContent {
                            display: flex;
                            flex-direction: column;
    
                            h3 {
                                font-size: 1.2rem;
                                font-weight: 600;
                                line-height: 1.5;
                                color: $color-black;
                                margin: 0;
    
                                // @media (max-width: $mobileBig) {
                                // 	font-size: 1rem;
                                // }
                            }
    
                            p {
                                font-size: 0.8rem;
                                font-weight: 400;
                                line-height: 1.5;
                                color: $color-black;
                                margin: 0.5rem 0 0;
                            }
    
                            .btn {
                                font-size: 1rem;
                                font-weight: 600;
                                line-height: 1.5;
                                color: $color-white;
    
                                background-color: #262c30;
                                padding: 0.75rem 1.5rem;
                                border-radius: 0.75rem;
                                margin: 1rem 0 0;
    
                                // @media (max-width: $mobile - 1) {
                                // 	padding: 0.5rem 1rem;
                                // 	font-size: 0.8rem;
                                // }
                            }
                        }
                    }
    
                    #{$dashboard}__headingRight {
                        display: flex;
                        flex-direction: column;
    
                        min-width: 12.5rem;
    
                        .css-1fdsijx-ValueContainer {
                            font-size: 0.8rem;
                            padding: 0.4rem 0.9rem !important;
                        }
                    }
                }
    
                .no_sample_campaign{
                    width: 40%;
                    margin: 0 auto;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    padding: 1.5rem;
                    border-radius: 0.5rem;
                    background-color: #FFF;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                    text-align: center;
    
                    @media only screen and (max-width: 768px){
                        width: 100%;
                    }
    
                    img{
                        margin-bottom: 0.5rem;
                    }
    
                    h4{
                        color: #000;
                    }
                }
    
                #{$dashboard}__dropdown {
                    display: flex;
    
                    justify-content: end;
    
                    margin-bottom: 2.5rem;
    
                    @media (max-width: $mobileBig) {
                        flex-direction: column;
                    }
    
                    #{$dashboard}__headingLeft {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
    
                        @media (max-width: $mobileBig) {
                            align-items: center;
                            justify-content: center;
    
                            width: 100%;
                            margin-bottom: 1.5rem;
                            text-align: center;
                        }
    
                        #{$dashboard}__headingBackLink {
                            display: flex;
                            flex-direction: column;
    
                            a {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
    
                                width: 1.75rem;
                                height: 1.75rem;
    
                                img {
                                    width: 1.25rem;
                                }
                            }
                        }
    
                        #{$dashboard}__headingContent {
                            display: flex;
                            flex-direction: column;
    
                            h3 {
                                font-size: 1.2rem;
                                font-weight: 600;
                                line-height: 1.5;
                                color: $color-black;
                                margin: 0;
                            }
    
                            p {
                                font-size: 0.8rem;
                                font-weight: 400;
                                line-height: 1.5;
                                color: $color-black;
                                margin: 0.5rem 0 0;
                            }
    
                            .btn {
                                font-size: 1rem;
                                font-weight: 600;
                                line-height: 1.5;
                                color: $color-white;
    
                                background-color: $primary-color;
                                padding: 0.75rem 1.5rem;
                                border-radius: 0.75rem;
                                margin: 1rem 0 0;
    
                                // @media (max-width: $mobile - 1) {
                                // 	padding: 0.5rem 1rem;
                                // 	font-size: 0.8rem;
                                // }
                            }
                        }
                    }
    
                    #{$dashboard}__headingRight {
                        display: flex;
                        flex-direction: column;
    
                        min-width: 9.5rem;
    
                        .css-1fdsijx-ValueContainer {
                            font-size: 0.8rem;
                            padding: 0.4rem 0.9rem !important;
                        }
                    }
                }
    
                #{$dashboard}__tabSection {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
    
                    margin: 1.5rem 0 0;
    
                    .nav.nav-tabs {
                        padding: 0;
                        margin: 0 0 -0.05rem;
                        border: none;
                        border-top-left-radius: 0.375rem;
                        border-top-right-radius: 0.375rem;
                        overflow: hidden;
                        position: relative;
                        z-index: 1;
    
                        @media (max-width: $mobileBig) {
                        }
    
                        .nav-item {
                            display: block;
    
                            @media (max-width: $mobileBig) {
                                width: 100%;
                            }
    
                            &:first-child {
                                .nav-link {
                                    border-top-left-radius: 0.375rem;
    
                                    // @media (max-width: $mobileBig) {
                                    // 	border-top-right-radius: 0.375rem;
                                    // }
                                }
                            }
    
                            &:last-child {
                                .nav-link {
                                    border-top-right-radius: 0.375rem;
                                    margin-right: 0;
    
                                    @media (max-width: $mobileBig) {
                                        border-top-right-radius: 0;
                                    }
                                }
                            }
    
                            .nav-link {
                                display: flex;
                                align-items: center;
                                justify-content: center;
    
                                font-size: 0.9rem;
                                font-weight: 600;
                                color: $color-black;
                                letter-spacing: 0.025rem;
                                padding: 0.6rem 1.5rem;
                                margin: 0;
                                margin-right: -0.05rem;
                                border-radius: 0;
                                background-color: $primary-bgColor;
                                border: 0.05rem solid $primary-color;
                                position: relative;
                                z-index: 0;
    
                                @media (max-width: $mobileBig) {
                                    width: 100%;
                                }
    
                                &:before {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 0;
                                    background-color: $primary-color;
                                    z-index: 1;
                                }
    
                                &.active {
                                    border-bottom: 0.05rem solid $primary-bgColor;
    
                                    &:before {
                                        height: 0.35rem;
                                    }
                                }
                            }
                        }
                    }
    
                    .tab-content {
                        display: flex;
                        flex-direction: column;
    
                        padding: 2rem;
                        background-color: $primary-bgColor;
                        border: 0.05rem solid $primary-color;
                        position: relative;
                        z-index: 0;
                        width: 100%;
    
                        // @media (max-width: $mobileBig) {
                        // 	padding: 1rem;
                        // }
    
                        // @media (max-width: $mobile - 1) {
                        // 	padding: 0.75rem;
                        // }
    
                        .tab-pane {
                            .customForm {
                                display: flex;
                                flex-direction: column;
    
                                .form-group {
                                    display: flex;
                                    flex-direction: column;
    
                                    margin: 0 0 1rem;
                                    .css-qbdosj-Input {
                                        margin: 0;
                                        padding: 0;
                                    }
    
                                    .no____value {
                                        .css-13cymwt-control {
                                            border-color: #0047ab;
                                        }
                                    }
                                    .css-13cymwt-control {
                                        border-radius: $inp_radius;
                                        // border-radius: 0.375rem;
                                        // min-height: 44px;
    
                                        .css-1xc3v61-indicatorContainer {
                                            padding: 0.215rem !important;
                                        }
                                    }
                                    .css-1jqq78o-placeholder {
                                        margin: 0;
                                        font-size: 0.8rem;
                                        color: #191a1a;
                                    }
                                    .css-1fdsijx-ValueContainer {
                                        padding: 0.4rem 0.9rem !important;
                                        font-size: 1rem !important;
                                    }
                                    @media screen and (min-width: $tabletSmall) and (max-width: $desktopSmall) {
                                        .css-1fdsijx-ValueContainer {
                                            padding: 0.4rem 0.9rem !important;
                                            font-size: 0.8rem !important;
                                        }
                                    }
                                    .css-1dimb5e-singleValue {
                                        font-size: 0.8rem;
                                    }
                                    .css-t3ipsp-control {
                                        border-radius: $inp_radius;
                                        // min-height: 44px;
    
                                        .css-15lsz6c-indicatorContainer {
                                            padding: 0.215rem !important;
                                        }
                                    }
    
                                    &.withSwitch {
                                        flex-direction: row;
    
                                        margin: 0;
    
                                        label {
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-start;
    
                                            margin: 0;
    
                                            .react-switch {
                                                margin-left: 1rem;
                                            }
                                        }
                                    }
    
                                    label {
                                        color: #191a1a;
                                        margin: 0 0 0.5rem;
    
                                        .react-switch {
                                            margin-left: 1rem;
                                        }
                                    }
    
                                    .form-control {
                                        padding: 0.4rem 0.9rem 0.4rem 0.6rem !important;
                                        border-radius: $inp_radius;
                                        outline: none !important;
                                        box-shadow: none !important;
                                        font-size: 0.8rem;
    
                                        &.no____value{
                                            border-color: #0047ab;
                                        }
                                    }
    
                                    .css-319lph-ValueContainer {
                                        padding: 0.45rem 0.9rem !important;
                                    }
                                }
    
                                .button-section {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-end;
    
                                    @media (max-width: $mobileBig) {
                                        flex-direction: column;
                                    }
    
                                    a {
                                        .btn {
                                            @media (max-width: $mobileBig) {
                                                margin-bottom: 1rem !important;
                                            }
                                        }
                                    }
    
                                    .btn {
                                        margin-left: 0.75rem;
    
                                        @media (max-width: $mobileBig) {
                                            width: 100%;
                                            margin: 0 0 1rem;
                                        }
    
                                        &:first-child {
                                            margin-left: 0;
                                        }
    
                                        &:last-child {
                                            @media (max-width: $mobileBig) {
                                                margin: 0;
                                            }
                                        }
                                    }
                                }
                            }
    
                            .customTable {
                                $customTable: ".customTable";
    
                                display: flex;
                                flex-direction: column;
    
                                #{$customTable}__header {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
    
                                    margin-bottom: 2rem;
    
                                    @media (max-width: $mobileBig) {
                                        flex-direction: column;
    
                                        // margin-bottom: 1rem;
                                    }
    
                                    #{$customTable}__left {
                                        display: flex;
                                        flex-direction: column;
    
                                        @media (max-width: $mobileBig) {
                                            margin-bottom: 1rem;
                                        }
    
                                        #{$customTable}__showingRecords {
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-start;
    
                                            .form-group {
                                                display: flex;
                                                align-items: center;
                                                justify-content: flex-start;
                                                flex-direction: row;
    
                                                label {
                                                    color: #191a1a;
                                                    display: block;
                                                    margin: 0 0.5rem 0 0;
                                                }
    
                                                .form-control {
                                                    padding: 0.6rem 0.9rem 0.6rem 0.6rem !important;
                                                    border-radius: 0.375rem;
                                                    outline: none !important;
                                                    box-shadow: none !important;
                                                    min-width: 1.75rem;
                                                    width: auto;
                                                }
                                            }
                                        }
                                    }
    
                                    #{$customTable}__right {
                                        display: flex;
                                        flex-direction: column;
    
                                        #{$customTable}__searchFilter {
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-end;
    
                                            @media (max-width: $mobileBig) {
                                                justify-content: center;
                                                flex-direction: column;
                                            }
    
                                            label {
                                                color: #191a1a;
                                                display: block;
                                                margin: 0;
                                                margin-right: 0.5rem;
    
                                                @media (max-width: $mobileBig) {
                                                    margin-right: 0;
                                                    margin-bottom: 0.5rem;
                                                }
                                            }
    
                                            .form-control {
                                                padding: 0.6rem 0.9rem;
                                                border-radius: 0.375rem;
                                                outline: none !important;
                                                box-shadow: none !important;
                                                width: auto;
    
                                                @media (max-width: $mobileBig) {
                                                    width: 100%;
                                                }
                                            }
                                        }
                                    }
                                }
    
                                #{$customTable}__body {
                                    display: flex;
                                    flex-direction: column;
    
                                    margin-bottom: 1rem;
    
                                    .table-responsive {
                                        .table {
                                            thead {
                                                tr {
                                                    th {
                                                    }
                                                }
                                            }
    
                                            tbody {
                                                tr {
                                                    td {
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
    
                                #{$customTable}__footer {
                                    display: flex;
                                    align-items: flex-start;
                                    justify-content: space-between;
    
                                    @media (max-width: $mobileBig) {
                                        align-items: center;
                                        flex-direction: column;
                                    }
    
                                    #{$customTable}__left {
                                        display: flex;
                                        flex-direction: column;
    
                                        @media (max-width: $mobileBig) {
                                            margin-bottom: 1rem;
                                        }
    
                                        #{$customTable}__totalTransactions {
                                            display: flex;
                                            flex-direction: column;
    
                                            p {
                                                font-size: 0.8rem;
                                                font-weight: 400;
                                                color: $color-black;
                                                margin: 0;
    
                                                span {
                                                    display: inline-block;
                                                    font-weight: 700;
                                                }
                                            }
                                        }
                                    }
    
                                    #{$customTable}__right {
                                        display: flex;
                                        flex-direction: column;
    
                                        #{$customTable}__top {
                                            display: flex;
                                            flex-direction: column;
    
                                            margin-bottom: 1rem;
    
                                            #{$customTable}__totalPages {
                                                display: flex;
                                                flex-direction: column;
    
                                                p {
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: flex-end;
    
                                                    font-size: 0.8rem;
                                                    font-weight: 400;
                                                    color: $color-black;
                                                    margin: 0;
    
                                                    @media (max-width: $mobile - 1) {
                                                        justify-content: center;
                                                    }
    
                                                    .form-control {
                                                        font-size: 0.8rem;
                                                        line-height: 1.2;
                                                        border-radius: 0.375rem;
                                                        outline: none !important;
                                                        box-shadow: none !important;
                                                        width: auto;
                                                        margin: 0 0.25rem;
                                                        text-align: left;
                                                        min-width: 2rem;
                                                        padding: 0.375rem 0 0.375rem 0.375rem;
                                                    }
    
                                                    span {
                                                        display: inline-block;
                                                        font-weight: 700;
                                                        margin-left: 0.5rem;
                                                    }
                                                }
                                            }
                                        }
    
                                        #{$customTable}__bottom {
                                            display: flex;
                                            flex-direction: column;
    
                                            #{$customTable}__buttons {
                                                display: flex;
                                                align-items: center;
                                                justify-content: flex-end;
    
                                                span {
                                                    margin-right: 0.5rem;
    
                                                    &[disabled] {
                                                        cursor: default;
                                                        opacity: 0.7;
    
                                                        &:hover {
                                                            border-color: #0d6efd;
                                                            color: #0d6efd;
                                                            background-color: transparent;
                                                        }
                                                    }
    
                                                    &:last-child {
                                                        margin: 0;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                #{$dashboard}__dashboardCards {
                    display: flex;
                    flex-wrap: wrap;
    
                    #{$dashboard}__dashboardCard {
                        display: flex;
                        flex-direction: column;
    
                        overflow: hidden;
                        border-radius: 0.5rem;
                        width: calc(((100% / 3) * 1) - ((1.25rem / 3) * 2));
                        margin-right: 1.25rem;
                        margin-bottom: 1.25rem;
    
                        &--first {
                        }
    
                        &--second {
                        }
    
                        &--third {
                            margin-right: 0;
                        }
    
                        &--fourth {
                            width: calc(((100% / 3) * 2) - (1.25rem / 3));
                            margin-bottom: 0;
    
                            @media (max-width: $desktopSmall - 1) {
                                margin-bottom: 1.25rem;
                            }
                        }
    
                        &--fifth {
                            margin-right: 0;
                            margin-bottom: 0;
                        }
    
                        @media (max-width: $desktopSmall - 1) {
                            width: 100% !important;
                            margin-right: 0;
                        }
    
                        #{$dashboard}__dashboardCardHeader {
                            display: flex;
                            align-items: center;
                            justify-content: center;
    
                            background-color: $primary-color;
                            padding: 1rem;
                            text-align: center;
    
                            h4 {
                                font-size: 1rem;
                                font-weight: 600;
                                line-height: 1.5;
                                color: $color-white;
                                margin: 0;
    
                                // @media (max-width: $mobileBig) {
                                // 	font-size: 0.8rem;
                                // }
                            }
                        }
    
                        #{$dashboard}__dashboardCardBody {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
    
                            padding: 1rem;
                            background-color: $primary-bgColor;
                            text-align: center;
    
                            #{$dashboard}__dashboardCardLeft {
                                display: flex;
                                align-items: center;
                                justify-content: center;
    
                                width: 3.6rem;
                                height: 3.6rem;
                                border-radius: 1rem;
                                background-color: #d9eeff;
                                margin-right: 1rem;
    
                                // @media (max-width: $mobileBig) {
                                // 	width: 2.8rem;
                                // 	height: 2.8rem;
                                // }
    
                                img {
                                    width: 2rem;
                                    height: 2rem;
    
                                    // @media (max-width: $mobileBig) {
                                    // 	width: 1.5rem;
                                    // 	height: 1.5rem;
                                    // }
                                }
                            }
    
                            #{$dashboard}__dashboardCardRight {
                                display: flex;
                                flex-direction: column;
    
                                width: calc(100% - 4.6rem);
                                text-align: left;
                            }
    
                            h2 {
                                font-size: 1.6rem;
                                font-weight: 600;
                                line-height: 1.5;
                                color: $color-black;
                                margin: 0;
    
                                // @media (max-width: $mobileBig) {
                                // 	font-size: 1.2rem;
                                // }
                            }
    
                            h4 {
                                font-size: 0.95rem;
                                font-weight: 400;
                                line-height: 1.5;
                                color: $color-black;
                                margin: 0.1rem 0 0;
    
                                // @media (max-width: $mobileBig) {
                                // 	font-size: 0.8rem;
                                // }
                            }
    
                            #{$dashboard}__lineChartDashboard,
                            #{$dashboard}__doughnutChartDashboard {
                                position: relative;
                                z-index: 0;
                                width: 100%;
    
                                // &::before {
                                // 	content: "";
                                // 	position: absolute;
                                // 	bottom: 0;
                                // 	left: 50%;
                                // 	transform: translateX(-50%);
                                // 	width: 91%;
                                // 	height: 91%;
                                // 	background-color: #dddddd;
                                // 	border-radius: 50%;
                                // 	z-index: -1;
                                // }
    
                                // &::after {
                                // 	content: "";
                                // 	position: absolute;
                                // 	bottom: 0;
                                // 	left: 50%;
                                // 	transform: translate(-50%, -50%);
                                // 	width: 45%;
                                // 	height: 45%;
                                // 	background-color: $color-white;
                                // 	border-radius: 50%;
                                // 	z-index: -1;
                                // }
    
                                // &:hover {
                                // 	&::before {
                                // 		background-color: #c7c7c7;
                                // 	}
                                // }
    
                                select {
                                    position: absolute;
                                    top: -0.5rem;
                                    right: 0;
                                    max-width: 9rem;
                                    padding: 0.25rem 0.5rem;
                                    font-size: 0.75rem;
                                }
    
                                canvas {
                                    width: 100% !important;
                                    height: auto !important;
                                }
                            }
    
                            #{$dashboard}__lineChart {
                                padding-top: 1rem;
                            }
                        }
                    }
                }
    
                .sc_boxed {
                    display: flex;
                }
    
                #{$dashboard}__programsCard {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    padding: 1.25rem;
                    margin: 0 0 1.5rem;
                    // border: 0.05rem solid #50a3e5;
                    box-shadow: 0 0 0.75rem 0.25rem rgba(0, 0, 0, 0.1);
                    background-color: $primary-bgColor;
                    border: #7c7c7c solid 1px;
                    border-radius: 15px;
    
                    // .actionBtnRow {
                    //     a {
                    //         $size: 30px;
                    //         width: $size;
                    //         height: $size;
                    //         background: #f1f1f1;
                    //         border-radius: 50%;
                    //     }
                    // }
    
                    i {
                        color: #7c7c7c;
                    }
                    
                    button {
                        &.themeData {
                            background: #7c7c7c;
                            color: $color-white;
                        }
                    }
    
                    &.activeCard {
                        border: #009601 solid 1px;
                        box-shadow: 0 0 0.75rem 0.25rem rgba(#009601, 0.5);
    
                        h5, p {
                            color: #252525;
                        }
    
                        i {
                            &.fa-eye {
                                color: #2056a5;
                            }
                            &.fa-edit {
                                color: #009601;
                            }
                            &.fa-trash {
                                color: #e7655a;
                            }
                        }
    
                        button {
                            &.themeData {
                                background: #009601;
                                color: $color-white;
                            }
                        }
                    }
    
                    // background: $primary-bgColor url("../Image/programsCard-bg.png") no-repeat center bottom -7rem / 100% auto;
    
                    // &:nth-last-child(1),
                    // &:nth-last-child(2) {
                    // 	margin-top: 0;
                    // }
    
                    // @media (max-width: $mobile - 1) {
                    // 	padding: 0.75rem;
                    // }
    
                    #{$dashboard}__header {
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
    
                        margin: 0 0 0.5rem;
    
                        // @media (max-width: $mobile - 1) {
                        // 	flex-direction: column;
                        // }
    
                        #{$dashboard}__left {
                            display: flex;
                            flex-direction: column;
                            // text-overflow: ellipsis;
                            // white-space: nowrap;
                            // overflow: hidden;
    
                            // @media (max-width: $mobile - 1) {
                            // 	width: 100%;
                            // }
    
                            h5 {
                                display: block;
                                align-items: center;
                                justify-content: flex-start;
    
                                font-size: 18px;
                                font-weight: 600;
                                line-height: 1.4;
                                color: #191a1a;
                                margin: 0 0 0.25rem;
                                height: 52px;
                                overflow: hidden;
                                // text-overflow: ellipsis;
                                // white-space: nowrap;
                                // overflow: hidden;
    
                                span {
                                    display: block;
                                    width: 1rem;
                                    height: 1rem;
                                    margin-left: 0.5rem;
    
                                    &.activeCampaign {
                                        position: relative;
                                        align-self: flex-start;
                                        margin-top: 2px;
    
                                        &::before {
                                            content: "";
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 100%;
                                            border-radius: 50%;
                                            background-color: transparent;
                                            border: 0.1rem solid rgba(#50bc14, 0.5);
                                        }
    
                                        &::after {
                                            content: "";
                                            content: "";
                                            position: absolute;
                                            top: 50%;
                                            left: 50%;
                                            transform: translate(-50%, -50%);
                                            width: 0.5rem;
                                            height: 0.5rem;
                                            border-radius: 50%;
                                            background-color: rgba(#50bc14, 1);
                                        }
                                    }
    
                                    &.expiredCampaign {
                                        position: relative;
    
                                        &::before {
                                            content: "";
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 100%;
                                            border-radius: 50%;
                                            background-color: transparent;
                                            border: 0.1rem solid rgba(#e50000, 0.5);
                                        }
    
                                        &::after {
                                            content: "";
                                            content: "";
                                            position: absolute;
                                            top: 50%;
                                            left: 50%;
                                            transform: translate(-50%, -50%);
                                            width: 0.5rem;
                                            height: 0.5rem;
                                            border-radius: 50%;
                                            background-color: rgba(#e50000, 1);
                                        }
                                    }
                                }
    
                                // @media (max-width: $mobile - 1) {
                                // 	font-size: 0.8rem;
                                // }
                            }
    
                            p {
                                font-size: 0.55rem;
                                font-weight: 400;
                                line-height: 1.5;
                                color: #191a1a;
                                margin: 0;
                            }
                        }
    
                        #{$dashboard}__right {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
    
                            // @media (max-width: $mobile - 1) {
                            // 	width: 100%;
                            // }
    
                            ul {
                                display: flex;
                                flex-direction: row;
    
                                margin: 0;
                                padding: 0;
    
                                li {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
    
                                    position: relative;
                                    margin-left: 0.5rem;
    
                                    &:first-child {
                                        margin-left: 0;
                                    }
    
                                    #{$dashboard}__edit,
                                    #{$dashboard}__options,
                                    #{$dashboard}__create {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
    
                                        cursor: pointer;
                                        width: 1.25rem;
                                        height: 1.25rem;
    
                                        i {
                                            color: $color-black;
                                            font-size: 0.8rem;
                                        }
    
                                        // @media (max-width: $mobile - 1) {
                                        // 	width: 1rem;
                                        // 	height: 1rem;
                                        // }
                                    }
    
                                    #{$dashboard}__options {
                                        margin-left: 0.75rem;
    
                                        // @media (max-width: $mobile - 1) {
                                        // 	margin-left: 0.25rem;
                                        // }
                                    }
    
                                    #{$dashboard}__optionsBox {
                                        display: flex;
                                        flex-direction: column;
    
                                        background-color: $color-white;
                                        border: 0.05rem solid rgba(0, 0, 0, 0.175);
                                        border-radius: 0.375rem;
                                        position: absolute;
                                        top: calc(100% + 1rem);
                                        right: 0;
                                        width: auto;
                                        min-width: 11rem;
                                        padding: 0;
                                        transform-origin: 0 0 0;
                                        z-index: 999;
    
                                        opacity: 0;
                                        visibility: hidden;
                                        transform: scaleY(0);
    
                                        &.show {
                                            opacity: 1;
                                            visibility: visible;
                                            transform: scaleY(1);
                                        }
    
                                        ul {
                                            display: flex;
                                            flex-direction: column;
    
                                            margin: 0;
                                            padding: 0;
    
                                            li {
                                                display: flex;
                                                align-items: flex-start;
                                                flex-direction: column;
    
                                                border-bottom: 0.05rem solid $primary-bgColor;
    
                                                &:last-child {
                                                    border-bottom: none;
                                                }
    
                                                span {
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: flex-start;
    
                                                    color: $color-black;
                                                    padding: 0.75rem 0.75rem;
                                                    transition: all 0.5s;
                                                    border-radius: 0;
                                                    width: 100%;
                                                    cursor: pointer;
    
                                                    &.expire {
                                                        color: red;
                                                    }
    
                                                    i {
                                                        margin-right: 0.5rem;
                                                    }
                                                }
                                            }
                                        }
                                    }
    
                                    #{$dashboard}__create {
                                        i {
                                            font-size: 1rem;
                                        }
                                    }
    
                                    span {
                                        display: block;
                                        width: 1rem;
                                        height: 1rem;
                                        margin-left: 0.5rem;
    
                                        &.activeCampaign {
                                            position: relative;
                                            align-self: flex-start;
                                            margin-top: 2px;
    
                                            &::before {
                                                content: "";
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                width: 100%;
                                                height: 100%;
                                                border-radius: 50%;
                                                background-color: transparent;
                                                border: 0.1rem solid rgba(#50bc14, 0.5);
                                            }
    
                                            &::after {
                                                content: "";
                                                content: "";
                                                position: absolute;
                                                top: 50%;
                                                left: 50%;
                                                transform: translate(-50%, -50%);
                                                width: 0.5rem;
                                                height: 0.5rem;
                                                border-radius: 50%;
                                                background-color: rgba(#50bc14, 1);
                                            }
                                        }
    
                                        &.expiredCampaign {
                                            position: relative;
    
                                            &::before {
                                                content: "";
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                width: 100%;
                                                height: 100%;
                                                border-radius: 50%;
                                                background-color: transparent;
                                                border: 0.1rem solid rgba(#e50000, 0.5);
                                            }
    
                                            &::after {
                                                content: "";
                                                content: "";
                                                position: absolute;
                                                top: 50%;
                                                left: 50%;
                                                transform: translate(-50%, -50%);
                                                width: 0.5rem;
                                                height: 0.5rem;
                                                border-radius: 50%;
                                                background-color: rgba(#e50000, 1);
                                            }
                                        }
                                    }
                                }
                            }
    
                            #{$dashboard}__ccampaign {
                                position: absolute;
                                bottom: 0.5rem;
                                right: 1.5rem;
    
                                a {
                                    color: #000000;
                                    font-size: 1rem;
                                    font-weight: 700;
                                }
                            }
                        }
                    }
    
                    #{$dashboard}__body {
                        display: flex;
                        flex-direction: column;
                        margin: 0 0 1rem;
                        height: 20px;
    
                        @media (max-width: $mobileBig) {
                            width: 100%;
                            text-align: center;
    
                            // margin: 0 0 1rem;
                        }
    
                        p {
                            font-size: 0.8rem;
                            font-weight: 400;
                            line-height: 1.5;
                            color: #191a1a;
                            margin: 0;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                    }
    
                    #{$dashboard}__link {
                        @media (min-width: $laptop) {
                            font-size: 0.6rem;
                        }
    
                        a.edit_btn{
                            position: relative;
    
                            .show_div{
                                position: absolute;
                                z-index: 999;
                                color: #fff;
                                padding: 4px 10px;
                                background-color: #0d6efd;
                                border-radius: 4px;
                                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                                display: none;
                                top: -28px;
                                left: -45px;
                                font-size: 10px;
                            }
    
                            &:hover{
                                .show_div{
                                    display: flex;
                                }
                            }
                        }
                    }
    
                    #{$dashboard}__footer {
                        display: flex;
                        flex-direction: column;
    
                        @media (max-width: $mobileBig) {
                            text-align: center;
                        }
    
                        p {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
    
                            margin: 0;
    
                            @media (max-width: $mobileBig) {
                                flex-direction: column;
                            }
    
                            span {
                                display: inline-block;
                                font-size: 0.6rem;
                                font-weight: 400;
                                line-height: 1.5;
                                color: #191a1a;
                                max-width: 50%;
    
                                strong {
                                    display: inline-block;
                                    font-weight: 700;
    
                                    @media (max-width: $desktopSmall - 1) {
                                        display: block;
                                    }
    
                                    @media (max-width: $mobileBig) {
                                        display: inline-block;
                                    }
                                }
    
                                @media (max-width: $mobileBig) {
                                    max-width: none;
    
                                    &:first-child {
                                        margin: 0 0 0.5rem;
                                    }
                                }
                            }
                        }
                    }
    
                    &.dashboard__programsCardSC {
                        .dashboard__header {
                            .dashboard__left {
                                text-overflow: unset;
                                white-space: normal;
                                overflow: auto;
    
                                h5 {
                                    text-overflow: unset;
                                    white-space: normal;
                                    overflow: auto;
                                }
                            }
                        }
    
                        .dashboard__body {
                            height: auto;
    
                            p {
                                text-overflow: unset;
                                white-space: normal;
                                overflow: inherit;
                                padding-right: 1.5rem;
                            }
                        }
                    }
                }
    
                #{$dashboard}__customersCard {
                    display: flex;
                    flex-direction: column;
    
                    overflow: hidden;
                    border-radius: 0.5rem;
                    margin: 1.5rem 0 0;
    
                    &:first-child {
                        margin-top: 0;
                    }
    
                    #{$dashboard}__header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
    
                        padding: 0.6rem;
                        background-color: #262c30;
    
                        // @media (max-width: $mobileBig) {
                        // 	flex-direction: column;
    
                        // 	padding: 0.75rem;
                        // }
    
                        #{$dashboard}__left {
                            display: flex;
                            flex-direction: column;
    
                            h4 {
                                font-size: 1.2rem;
                                font-weight: 600;
                                line-height: 1.5;
                                color: $color-white;
                                margin: 0;
    
                                // @media (max-width: $mobileBig) {
                                // 	font-size: 0.9rem;
                                // }
                            }
                        }
    
                        #{$dashboard}__right {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
    
                            @media (max-width: $mobileBig) {
                                justify-content: center;
    
                                margin: 0.5rem 0 0;
                            }
    
                            .btn-default {
                                background-color: $color-white;
                                border-radius: 0.375rem;
                                padding: 0.5rem 1rem;
                                font-size: 0.8rem;
    
                                &:last-child {
                                    margin-left: 0.75rem;
                                }
    
                                img {
                                    width: 0.85rem;
                                    margin-right: 0.25rem;
                                }
    
                                // @media (max-width: $mobileBig) {
                                // 	font-size: 0.6rem;
                                // 	padding: 0.2rem 0.4rem;
    
                                // 	&:last-child {
                                // 		margin-left: 0.375rem;
                                // 	}
                                // }
                            }
                        }
                    }
    
                    #{$dashboard}__body {
                        padding: 1rem;
                        background-color: $primary-bgColor;
    
                        p {
                            font-size: 0.8rem;
                            font-weight: 500;
                            line-height: 1.5;
                            color: $color-black;
                            margin: 0 0 0.25rem;
    
                            &:last-child {
                                margin: 0;
                            }
                        }
    
                        .customForm {
                            display: flex;
                            flex-direction: column;
    
                            .form-group {
                                display: flex;
                                flex-direction: column;
    
                                margin: 0 0 1rem;
    
                                label {
                                    color: #191a1a;
                                    margin: 0 0 0.5rem;
                                }
    
                                .form-control {
                                    padding: 0.6rem 0.9rem;
                                    border-radius: 0.375rem;
                                    outline: none !important;
                                    box-shadow: none !important;
                                }
                            }
                        }
    
                        .customTable {
                            $customTable: ".customTable";
    
                            display: flex;
                            flex-direction: column;
    
                            #{$customTable}__header {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
    
                                margin-bottom: 2rem;
    
                                @media (max-width: $mobileBig) {
                                    flex-direction: column;
    
                                    // margin-bottom: 1rem;
                                }
    
                                #{$customTable}__left {
                                    display: flex;
                                    flex-direction: column;
    
                                    @media (max-width: $mobileBig) {
                                        margin-bottom: 1rem;
                                    }
    
                                    #{$customTable}__showingRecords {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;
    
                                        .form-group {
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-start;
    
                                            label {
                                                color: #191a1a;
                                                display: block;
                                                margin: 0 0.5rem 0 0;
                                            }
    
                                            .form-control {
                                                padding: 0.4rem 0.9rem 0.4rem 0.6rem !important;
                                                border-radius: $inp_radius;
                                                outline: none !important;
                                                box-shadow: none !important;
                                                min-width: 1.75rem;
                                                width: auto;
                                                height: $inp_height;
                                            }
                                        }
                                    }
    
                                    &.custonTable_fullwidth{
    
                                        .dashboard__customerViewPointsTabs{
    
                                            ul.nav.nav-tabs{
                                                display: flex;
                                                flex-direction: row;
                                                border: none;
                                                margin: 0 0 1.5rem;
    
                                                li.nav-item{
                                                    display: flex;
                                                    flex-direction: column;
    
                                                    button.nav-link{
                                                        display: flex;
                                                        flex-direction: column;
                                                        font-size: 1rem;
                                                        line-height: 1.2;
                                                        color: #282828;
                                                        border: none;
                                                        border-bottom: 0.15rem solid #cfcfcf;
                                                        margin: 0;
                                                        background-color: transparent;
                                                        padding: 1rem 3rem;
    
                                                        &.active{
                                                            border-color: #2056a5;
                                                            color: #2056a5;
                                                        }
                                                    }
                                                }
                                            }
                                        }
    
                                    }
                                }
    
                                #{$customTable}__right {
                                    display: flex;
                                    flex-direction: column;
    
                                    #{$customTable}__searchFilter {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-end;
    
                                        @media (max-width: $mobileBig) {
                                            justify-content: center;
                                            flex-direction: column;
                                        }
    
                                        label {
                                            color: #191a1a;
                                            display: block;
                                            margin: 0;
                                            margin-right: 0.5rem;
    
                                            @media (max-width: $mobileBig) {
                                                margin-right: 0;
                                                margin-bottom: $inp_radius;
                                            }
                                        }
    
                                        .form-control {
                                            padding: 0.6rem 0.9rem;
                                            border-radius: $inp_radius;
                                            outline: none !important;
                                            box-shadow: none !important;
                                            width: auto;
                                            height: $inp_height;
    
                                            @media (max-width: $mobileBig) {
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                            }
    
                            #{$customTable}__body {
                                display: flex;
                                flex-direction: column;
    
                                margin-bottom: 1rem;
    
                                .table-responsive {
                                    .table {
                                        thead {
                                            tr {
                                                th {
                                                }
                                            }
                                        }
    
                                        tbody {
                                            tr {
                                                td {
                                                    a {
                                                        color: #0d6efd;
                                                        position: relative;
                                                        margin-right: 10px;
    
                                                        .show_div {
                                                            visibility: hidden;
                                                            width: 150px;
                                                            max-width: 300px;
                                                            background-color: #0d6efd;
                                                            color: #fff;
                                                            text-align: center;
                                                            border-radius: 6px;
                                                            padding: 5px 0;
                                                            position: absolute;
                                                            z-index: 1;
                                                            bottom: 150%;
                                                            left: 50%;
                                                            margin-left: -75px;
                                                            opacity: 0;
                                                            transition: opacity 0.3s;
                                                            font-size: 11px;
    
                                                            &::after {
                                                                content: "";
                                                                position: absolute;
                                                                top: 100%;
                                                                left: 50%;
                                                                margin-left: -5px;
                                                                border-width: 5px;
                                                                border-style: solid;
                                                                border-color: #0d6efd transparent transparent transparent;
                                                            }
                                                        }
    
                                                        &:hover {
                                                            .show_div {
                                                                visibility: visible;
                                                                opacity: 1;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
    
                            #{$customTable}__footer {
                                display: flex;
                                align-items: flex-start;
                                justify-content: space-between;
    
                                @media (max-width: $mobileBig) {
                                    align-items: center;
                                    flex-direction: column;
                                }
    
                                #{$customTable}__left {
                                    display: flex;
                                    flex-direction: column;
    
                                    @media (max-width: $mobileBig) {
                                        margin-bottom: 1rem;
                                    }
    
                                    #{$customTable}__totalTransactions {
                                        display: flex;
                                        flex-direction: column;
    
                                        p {
                                            font-size: 0.8rem;
                                            font-weight: 400;
                                            color: $color-black;
                                            margin: 0;
    
                                            span {
                                                display: inline-block;
                                                font-weight: 700;
                                            }
                                        }
                                    }
                                }
    
                                #{$customTable}__right {
                                    display: flex;
                                    flex-direction: column;
    
                                    #{$customTable}__top {
                                        display: flex;
                                        flex-direction: column;
    
                                        margin-bottom: 1rem;
    
                                        #{$customTable}__totalPages {
                                            display: flex;
                                            flex-direction: column;
    
                                            p {
                                                display: flex;
                                                align-items: center;
                                                justify-content: flex-end;
    
                                                font-size: 0.8rem;
                                                font-weight: 400;
                                                color: $color-black;
                                                margin: 0;
    
                                                @media (max-width: $mobile - 1) {
                                                    justify-content: center;
                                                }
    
                                                .form-control {
                                                    font-size: 0.8rem;
                                                    line-height: 1.2;
                                                    border-radius: 0.375rem;
                                                    outline: none !important;
                                                    box-shadow: none !important;
                                                    width: auto;
                                                    margin: 0 0.25rem;
                                                    text-align: left;
                                                    min-width: 2rem;
                                                    padding: 0.375rem 0 0.375rem 0.375rem;
                                                }
    
                                                span {
                                                    display: inline-block;
                                                    font-weight: 700;
                                                    margin-left: 0.5rem;
                                                }
                                            }
                                        }
                                    }
    
                                    #{$customTable}__bottom {
                                        display: flex;
                                        flex-direction: column;
    
                                        #{$customTable}__buttons {
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-end;
    
                                            span {
                                                margin-right: 0.5rem;
    
                                                &[disabled] {
                                                    cursor: default;
                                                    opacity: 0.7;
    
                                                    &:hover {
                                                        border-color: #0d6efd;
                                                        color: #0d6efd;
                                                        background-color: transparent;
                                                    }
                                                }
    
                                                &:last-child {
                                                    margin: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                #{$dashboard}__transactionsCard {
                    display: flex;
                    flex-direction: column;
                    margin: 1.5rem 0 0;
    
                    &:first-child {
                        margin-top: 0;
                    }
    
                    #{$dashboard}__header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
    
                        background-color: #262c30;
                        border-top-left-radius: 0.5rem;
                        border-top-right-radius: 0.5rem;
                        padding: 0.6rem;
    
                        // @media (max-width: $mobileBig) {
                        // 	padding: 0.75rem;
                        // }
    
                        #{$dashboard}__left {
                            display: flex;
                            flex-direction: column;
    
                            h4 {
                                font-size: 1rem;
                                font-weight: 600;
                                line-height: 1.5;
                                color: $color-white;
                                margin: 0;
    
                                // @media (max-width: $mobileBig) {
                                // 	font-size: 0.9rem;
                                // }
                            }
                        }
    
                        #{$dashboard}__right {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
    
                            @media (max-width: $mobileBig) {
                                justify-content: center;
    
                                margin: 0.5rem 0 0;
                            }
    
                            .btn-default {
                                background-color: $color-white;
                                border-radius: $inp_radius;
                                padding: 0.3rem 0.8rem;
                                font-size: 0.8rem;
    
                                &:last-child {
                                    margin-left: 0.75rem;
                                }
    
                                img {
                                    width: 0.85rem;
                                    margin-right: 0.25rem;
                                }
    
                                // @media (max-width: $mobileBig) {
                                // 	font-size: 0.6rem;
                                // 	padding: 0.2rem 0.4rem;
    
                                // 	&:last-child {
                                // 		margin-left: 0.375rem;
                                // 	}
                                // }
                            }
                        }
                    }
    
                    #{$dashboard}__body {
                        padding: 1rem;
                        background-color: $primary-bgColor;
                        border-bottom-left-radius: 0.5rem;
                        border-bottom-right-radius: 0.5rem;
    
                        .integration-container {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 15px;
                        
                            .integration {
                                width: calc(100% / 3 - 15px);
                                border: #f1f1f1 solid 1px;
                                background-color: #fff;
                                padding: 30px;
                                border-radius: 10px;
                                text-align: center;
                                box-shadow: 0 0 16px rgba(#000, 0.1);
    
                                @media (max-width: 767px) {
                                    width: 100%;
                                }
                        
                                p {
                                    font-size: 14px;
                                    line-height: 1.5;
                                }
                        
                                a {
                                    height: 120px;
                                    display: inline-flex;
                                    align-items: center;
                                }
                        
                                h4 {
                                    height: 120px;
                                    display: inline-flex;
                                    align-items: center;
                                    color: #252525;
                                    font-size: 16px;
                                    font-weight: 700;
                                    margin-bottom: 0;
                                }
                            }
                        }
    
                        p {
                            font-size: 0.8rem;
                            font-weight: 500;
                            line-height: 1.5;
                            color: $color-black;
                            margin: 0 0 0.05rem;
    
                            &:last-child {
                                margin: 0;
                            }
                        }
    
                        .customForm {
                            display: flex;
                            flex-direction: column;
    
                            .form-group {
                                display: flex;
                                flex-direction: column;
    
                                margin: 0 0 1rem;
    
                                label {
                                    color: #191a1a;
                                    margin: 0 0 0.5rem;
                                }
    
                                .form-control {
                                    padding: 0.6rem 0.9rem;
                                    border-radius: 0.375rem;
                                    outline: none !important;
                                    box-shadow: none !important;
                                }
    
                                .btn {
                                    padding: 0.6rem 0.75rem;
                                }
                            }
                        }
    
                        .customTable {
                            $customTable: ".customTable";
    
                            display: flex;
                            flex-direction: column;
    
                            #{$customTable}__header {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
    
                                margin-bottom: 2rem;
    
                                @media (max-width: $mobileBig) {
                                    flex-direction: column;
    
                                    // margin-bottom: 1rem;
                                }
    
                                #{$customTable}__left {
                                    display: flex;
                                    flex-direction: column;
    
                                    @media (max-width: $mobileBig) {
                                        margin-bottom: 1rem;
                                    }
    
                                    #{$customTable}__showingRecords {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;
    
                                        .form-group {
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-start;
    
                                            label {
                                                color: #191a1a;
                                                display: block;
                                                margin: 0 0.5rem 0 0;
                                            }
    
                                            .form-control {
                                                padding: 0.4rem 0.9rem 0.4rem 0.6rem !important;
                                                border-radius: 0.375rem;
                                                outline: none !important;
                                                box-shadow: none !important;
                                                min-width: 1.75rem;
                                                width: auto;
                                                border-radius: $inp_radius;
                                                height: $inp_height;
                                            }
                                        }
                                    }
                                }
    
                                #{$customTable}__right {
                                    display: flex;
                                    flex-direction: column;
    
                                    #{$customTable}__searchFilter {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-end;
    
                                        @media (max-width: $mobileBig) {
                                            flex-direction: column;
                                        }
    
                                        label {
                                            color: #191a1a;
                                            display: block;
                                            margin: 0 0.5rem 0 0;
                                        }
    
                                        .form-control {
                                            padding: 0.6rem 0.9rem;
                                            border-radius: 4px;
                                            outline: none !important;
                                            box-shadow: none !important;
                                            width: auto;
                                        }
                                    }
                                }
                            }
    
                            #{$customTable}__body {
                                display: flex;
                                flex-direction: column;
    
                                margin-bottom: 1rem;
    
                                .table-responsive {
                                    .table {
                                        thead {
                                            tr {
                                                th {
                                                }
                                            }
                                        }
    
                                        tbody {
                                            tr {
                                                td {
                                                }
                                            }
                                        }
                                    }
                                }
                            }
    
                            #{$customTable}__footer {
                                display: flex;
                                align-items: flex-start;
                                justify-content: space-between;
    
                                @media (max-width: $mobileBig) {
                                    align-items: center;
                                    flex-direction: column;
                                }
    
                                #{$customTable}__left {
                                    display: flex;
                                    flex-direction: column;
    
                                    @media (max-width: $mobileBig) {
                                        margin-bottom: 1rem;
                                    }
    
                                    #{$customTable}__totalTransactions {
                                        display: flex;
                                        flex-direction: column;
    
                                        p {
                                            font-size: 0.8rem;
                                            font-weight: 400;
                                            color: $color-black;
                                            margin: 0;
    
                                            span {
                                                display: inline-block;
                                                font-weight: 700;
                                            }
                                        }
                                    }
                                }
    
                                #{$customTable}__right {
                                    display: flex;
                                    flex-direction: column;
    
                                    #{$customTable}__top {
                                        display: flex;
                                        flex-direction: column;
    
                                        margin-bottom: 1rem;
    
                                        #{$customTable}__totalPages {
                                            display: flex;
                                            flex-direction: column;
    
                                            p {
                                                display: flex;
                                                align-items: center;
                                                justify-content: flex-end;
    
                                                font-size: 0.8rem;
                                                font-weight: 400;
                                                color: $color-black;
                                                margin: 0;
    
                                                @media (max-width: $mobileBig) {
                                                    justify-content: center;
                                                }
    
                                                .form-control {
                                                    font-size: 0.8rem;
                                                    line-height: 1.2;
                                                    border-radius: 0.375rem;
                                                    outline: none !important;
                                                    box-shadow: none !important;
                                                    width: auto;
                                                    margin: 0 0.25rem;
                                                    text-align: left;
                                                    min-width: 2rem;
                                                    padding: 0.375rem 0 0.375rem 0.375rem;
                                                }
    
                                                span {
                                                    display: inline-block;
                                                    font-weight: 700;
                                                    margin-left: 0.25rem;
                                                }
                                            }
                                        }
                                    }
    
                                    #{$customTable}__bottom {
                                        display: flex;
                                        flex-direction: column;
    
                                        #{$customTable}__buttons {
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-end;
    
                                            span {
                                                margin-right: 0.5rem;
    
                                                &[disabled] {
                                                    cursor: default;
                                                    opacity: 0.7;
    
                                                    &:hover {
                                                        border-color: #0d6efd;
                                                        color: #0d6efd;
                                                        background-color: transparent;
                                                    }
                                                }
    
                                                &:last-child {
                                                    margin: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                #{$dashboard}__customFormBox {
                    display: flex;
                    flex-direction: column;
    
                    margin: 0 0 1.5rem;
    
                    #{$dashboard}__heading {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-direction: row;
    
                        margin: 0 0 1rem;
    
                        #{$dashboard}__left {
                            display: flex;
                            flex-direction: column;
    
                            h5 {
                                font-size: 1.2rem;
                                font-weight: 600;
                                line-height: 1.5;
                                color: $color-black;
                                margin: 0;
    
                                // @media (max-width: $mobileBig) {
                                // 	font-size: 1rem;
                                // }
    
                                // @media (max-width: $mobile - 1) {
                                // 	font-size: 0.8rem;
                                // }
                            }
    
                            h6 {
                                font-size: 1rem;
                                font-weight: 600;
                                line-height: 1.5;
                                color: $color-black;
                                margin: 0;
    
                                // @media (max-width: $mobileBig) {
                                // 	font-size: 0.9rem;
                                // }
                            }
                        }
    
                        #{$dashboard}__right {
                            display: flex;
                            flex-direction: column;
    
                            .btn {
                                padding: 0.3rem 0.6rem;
                            }
                        }
                    }
    
                    #{$dashboard}__customFormCard {
                        display: flex;
                        flex-direction: column;
    
                        background-color: $primary-bgColor;
                        border: 0.1rem solid #d2d3d3;
                        border-radius: 0.375rem;
    
                        #{$dashboard}__top {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
    
                            margin: -0.05rem -0.05rem 0;
    
                            #{$dashboard}__left {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
    
                                button {
                                    font-size: 0.7rem;
                                    font-weight: 700;
                                    line-height: 1.5;
                                    padding: 0.5rem 2rem;
                                    border-radius: 0;
                                    text-transform: uppercase;
                                    margin-left: -0.05rem;
    
                                    &:first-child {
                                        border-top-left-radius: 0.375rem;
                                    }
    
                                    // @media (max-width: $mobileBig) {
                                    // 	padding: 0.5rem 1rem;
                                    // }
    
                                    // @media (max-width: $mobile - 1) {
                                    // 	padding: 0.5rem;
                                    // 	font-size: 0.6rem;
                                    // }
                                }
                            }
    
                            #{$dashboard}__right {
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
    
                                button {
                                    font-size: 0.7rem;
                                    font-weight: 700;
                                    line-height: 1.5;
                                    padding: 0.5rem 2rem;
                                    border-radius: 0;
                                    margin-left: -0.05rem;
    
                                    &:last-child {
                                        border-top-right-radius: 0.375rem;
                                    }
    
                                    i {
                                        margin-right: 0.25rem;
                                    }
    
                                    // @media (max-width: $mobileBig) {
                                    // 	padding: 0.5rem 1rem;
                                    // }
    
                                    // @media (max-width: $mobile - 1) {
                                    // 	padding: 0.5rem;
                                    // 	font-size: 0.6rem;
                                    // }
                                }
                            }
                        }
    
                        #{$dashboard}__middle {
                            display: flex;
                            flex-direction: column;
    
                            padding: 1rem;
    
                            // @media (max-width: $mobile - 1) {
                            // 	padding: 0.75rem 0.75rem;
                            // }
    
                            > .row {
                                position: relative;
                            }
    
                            .css-1fdsijx-ValueContainer {
                                font-size: 0.8rem;
                                padding: 0.4rem 0.75rem !important;
                            }
    
                            input.form-control {
                                font-size: 0.8rem;
                            }
    
                            + #{$dashboard}__middle {
                                padding-top: 1rem;
                                border-top: 0.05rem solid #d2d3d3;
                                // margin-top: 1rem;
                            }
    
                            #{$dashboard}__close {
                                display: flex;
                                align-items: center;
                                justify-content: center;
    
                                position: absolute;
                                top: 0;
                                right: 0;
                                z-index: 1;
                                width: 1.25rem;
                                height: 1.25rem;
                                padding: 0;
                                border-radius: 50%;
                                background-color: #dc3545;
                                transform: translateY(-50%);
                                cursor: pointer;
    
                                i {
                                    font-size: 0.8rem;
                                    color: $color-white;
                                }
                            }
    
                            .communication_flow_for {
                                position: relative;
    
                                button {
                                    right: 0;
                                    display: inline-block;
                                    position: absolute;
                                    top: 0px;
                                    border-radius: 30px;
                                    padding: 10px;
                                    border: none;
                                    background-color: #dc3545;
                                    width: 40px;
                                    height: 40px;
                                    font-size: 16px;
                                    color: #fff;
    
                                    @media (max-width: $mobileBig) {
                                        top: -5px;
                                        padding: 5px;
                                        border: none;
                                        background-color: #dc3545;
                                        width: 25px;
                                        height: 25px;
                                        font-size: 12px;
                                    }
                                }
                            }
    
                            #{$dashboard}__box {
                                padding-bottom: 1rem;
                                border-bottom: 0.05rem solid #d2d3d3;
                                margin-bottom: 1rem;
    
                                &:last-child {
                                    padding-bottom: 0;
                                    border-bottom: none;
                                    margin-bottom: 0;
                                }
    
                                #{$dashboard}__heading {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    flex-direction: row;
    
                                    margin: 0 0 1rem;
    
                                    h6 {
                                        font-size: 1rem;
                                        font-weight: 600;
                                        line-height: 1.5;
                                        color: $color-black;
                                        margin: 0;
    
                                        // @media (max-width: $mobileBig) {
                                        // 	font-size: 0.9rem;
                                        // }
                                    }
                                }
                            }
                        }
    
                        #{$dashboard}__bottom {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
    
                            margin: 0 -0.05rem -0.05rem;
    
                            #{$dashboard}__right {
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
    
                                button {
                                    font-size: 0.7rem;
                                    font-weight: 700;
                                    line-height: 1.5;
                                    padding: 0.5rem 2rem;
                                    border-radius: 0;
                                    margin-left: -0.05rem;
    
                                    &:last-child {
                                        border-bottom-right-radius: 0.375rem;
                                    }
                                }
                            }
                        }
                    }
                }
    
                #{$dashboard}__apiPlaygroundBox {
                    display: flex;
                    flex-direction: column;
    
                    padding: 1.25rem;
                    // margin: 1.5rem 0 0;
                    background-color: $primary-bgColor;
                    border: 0.05rem solid #50a3e5;
    
                    // @media (max-width: $mobileBig) {
                    // 	padding: 0.75rem;
                    // }
    
                    #{$dashboard}__apiPlaygroundHeading {
                        display: flex;
                        flex-direction: column;
    
                        margin: 0 0 1rem;
    
                        // @media (max-width: $mobileBig) {
                        // 	margin: 0 0 1rem;
                        // }
    
                        h2 {
                            display: flex;
                            align-items: center;
    
                            font-size: 1.8rem;
                            font-weight: 600;
                            line-height: 1.5;
                            color: $color-black;
                            margin: 0;
    
                            // @media (max-width: $mobileBig) {
                            // 	font-size: 0.9rem;
                            // }
    
                            span {
                                font-size: 0.9rem;
                                margin-right: 0.5rem;
    
                                // @media (max-width: $mobileBig) {
                                // 	font-size: 0.7rem;
                                // }
                            }
                        }
    
                        .form-group {
                            margin: 0;
                        }
    
                        button {
                            margin-top: 1rem;
    
                            @media (max-width: $mobileBig) {
                                width: 100%;
                                // font-size: 0.8rem;
                            }
                        }
                    }
    
                    #{$dashboard}__apiPlaygroundBottom {
                        display: flex;
                        flex-wrap: wrap;
    
                        margin: 0 0 1rem;
    
                        #{$dashboard}__apiPlaygroundEndpoint {
                            display: flex;
                            flex-direction: column;
    
                            width: 100%;
                            margin: 0 0 1rem;
    
                            p {
                                display: flex;
                                flex-direction: row;
    
                                margin: 0;
    
                                @media (max-width: $mobileBig) {
                                    flex-direction: column;
                                }
    
                                span {
                                    display: inline-block;
                                    font-weight: 600;
    
                                    @media (max-width: $mobileBig) {
                                        margin: 0 0 0.5rem;
                                    }
                                }
    
                                a {
                                    display: block;
                                    margin-left: 0.5rem;
    
                                    @media (max-width: $mobileBig) {
                                        margin-left: 0;
                                        width: 100%;
                                        padding-bottom: 0.5rem;
                                        overflow: auto;
                                    }
                                }
                            }
                        }
    
                        #{$dashboard}__apiPlaygroundButton {
                            display: flex;
                            align-items: FLEX-START;
                            justify-content: center;
                            flex-direction: column;
    
                            width: 70%;
    
                            .btn {
                                padding: 0.5rem 2rem;
                            }
                        }
    
                        #{$dashboard}__apiPlaygroundSelectCampaigns {
                            display: flex;
                            flex-direction: column;
    
                            width: 30%;
                        }
                    }
    
                    #{$dashboard}__apiPlaygroundCardMain {
                        display: flex;
                        flex-direction: column;
    
                        margin: 0 0 2rem;
    
                        #{$dashboard}__apiPlaygroundCardHead {
                            display: flex;
                            flex-direction: column;
    
                            margin: 0 0 2rem;
    
                            h3 {
                                font-size: 1.2rem;
                                font-weight: 600;
                                line-height: 1.5;
                                color: $color-black;
                                margin: 0;
                            }
                        }
    
                        #{$dashboard}__apiPlaygroundCardBox {
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;
    
                            @media (max-width: $mobileBig) {
                                flex-direction: column;
                            }
    
                            #{$dashboard}__apiPlaygroundLeft {
                                display: flex;
                                flex-direction: column;
    
                                width: calc(50% - 1rem);
    
                                @media (max-width: $mobileBig) {
                                    width: 100%;
                                    margin: 0 0 1rem;
                                }
    
                                #{$dashboard}__requestSection {
                                    display: flex;
                                    flex-direction: column;
    
                                    background-color: #3e4442;
                                    border-radius: 0.75rem;
    
                                    #{$dashboard}__header {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
    
                                        padding: 1rem;
                                        border-bottom: 0.05rem solid #6b7572;
    
                                        #{$dashboard}__left {
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-start;
    
                                            h5 {
                                                font-size: 0.8rem;
                                                font-weight: 600;
                                                line-height: 1.5;
                                                color: $color-white;
                                                margin: 0;
    
                                                // @media (max-width: $mobileBig) {
                                                // 	font-size: 0.6rem;
                                                // }
                                            }
                                        }
    
                                        #{$dashboard}__right {
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-end;
    
                                            a {
                                                display: block;
                                                font-size: 0.7rem;
                                                padding: 0.3rem 0.6rem;
                                            }
                                        }
                                    }
    
                                    #{$dashboard}__body {
                                        height: 10rem;
                                        // overflow: auto;
                                        padding: 0;
                                        position: relative;
                                        z-index: 0;
    
                                        > textarea {
                                            max-height: 95.2rem;
                                            resize: none;
                                            background-color: transparent;
                                            border: none;
                                            border-radius: 0;
                                            box-shadow: none;
                                        }
    
                                        > div,
                                        > textarea {
                                            padding: 1rem;
                                        }
    
                                        p,
                                        div,
                                        span,
                                        textarea {
                                            font-size: 0.75rem;
                                            font-weight: 400;
                                            line-height: 1.5;
                                            color: $color-white;
                                            margin: 0;
                                            width: 100%;
                                            height: 100%;
    
                                            // @media (max-width: $mobileBig) {
                                            // 	font-size: 0.6rem;
                                            // }
                                        }
    
                                        .mainPreloaderMain {
                                            position: absolute !important;
                                        }
                                    }
    
                                    #{$dashboard}__footer {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-end;
    
                                        padding: 1rem;
                                        min-height: 4rem;
    
                                        button {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
    
                                            padding: 0;
                                            width: 2rem;
                                            height: 2rem;
    
                                            i {
                                                font-size: 0.8rem;
                                            }
                                        }
                                    }
                                }
                            }
    
                            #{$dashboard}__apiPlaygroundRight {
                                display: flex;
                                flex-direction: column;
    
                                width: calc(50% - 1rem);
    
                                @media (max-width: $mobileBig) {
                                    width: 100%;
                                }
    
                                #{$dashboard}__responseSection {
                                    display: flex;
                                    flex-direction: column;
    
                                    background-color: #f2f2f2;
                                    border-radius: 0.75rem;
    
                                    #{$dashboard}__header {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
    
                                        padding: 1rem;
                                        border-bottom: 0.05rem solid #c1c1c1;
    
                                        #{$dashboard}__left {
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-start;
    
                                            h5 {
                                                font-size: 0.8rem;
                                                font-weight: 600;
                                                line-height: 1.5;
                                                color: #5f666c;
                                                margin: 0;
    
                                                // @media (max-width: $mobileBig) {
                                                // 	font-size: 0.6rem;
                                                // }
                                            }
                                        }
    
                                        #{$dashboard}__right {
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-end;
    
                                            a {
                                                display: block;
                                                font-size: 0.7rem;
                                                padding: 0.3rem 0.6rem;
                                            }
                                        }
                                    }
    
                                    #{$dashboard}__body {
                                        height: 14rem;
                                        overflow: auto;
                                        padding: 1rem;
                                        position: relative;
                                        z-index: 0;
    
                                        p,
                                        div,
                                        span {
                                            font-size: 0.75rem;
                                            font-weight: 400;
                                            line-height: 1.5;
                                            color: #71767b;
                                            margin: 0;
    
                                            // @media (max-width: $mobileBig) {
                                            // 	font-size: 0.6rem;
                                            // }
                                        }
    
                                        .mainPreloaderMain {
                                            position: absolute !important;
                                        }
                                    }
    
                                    #{$dashboard}__footer {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-end;
    
                                        padding: 1rem;
                                        min-height: 4rem;
    
                                        button {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
    
                                            padding: 0;
                                            width: 2rem;
                                            height: 2rem;
    
                                            i {
                                                font-size: 0.8rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
    
                    #{$dashboard}__apiPlaygroundDetails {
                        margin: 0 0 1.5rem;
    
                        &:last-child {
                            margin: 0;
                        }
    
                        h4 {
                            font-size: 1.4rem;
                            font-weight: 400;
                            line-height: 1.5;
                            color: $color-black;
                            margin: 0 0 1rem;
    
                            // @media (max-width: $mobileBig) {
                            // 	font-size: 0.9rem;
                            // }
                        }
    
                        h5 {
                            font-size: 1.2rem;
                            font-weight: 400;
                            line-height: 1.5;
                            color: $color-black;
                            margin: 0 0 1rem;
    
                            // @media (max-width: $mobileBig) {
                            // 	font-size: 0.9rem;
                            // }
                        }
    
                        p {
                            font-size: 0.9rem;
                            font-weight: 400;
                            line-height: 1.5;
                            color: $color-black;
                            margin: 0 0 1.5rem;
    
                            &:last-child {
                                margin: 0;
                            }
    
                            // @media (max-width: $mobileBig) {
                            // 	font-size: 0.8rem;
                            // }
                        }
    
                        table {
                            margin: 0;
                        }
    
                        table tbody tr td.td_left {
                            text-align: left;
                        }
                    }
                }
    
                #{$dashboard}__importCustomers {
                    display: flex;
                    flex-wrap: wrap;
    
                    #{$dashboard}__importHeading {
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
    
                        width: calc((100% / 3) * 3);
                        margin: 0 0 1.5rem;
    
                        @media (max-width: $mobileBig) {
                            width: 100%;
                            // margin: 0 0 1rem;
                        }
    
                        a {
                            display: block;
                            font-size: 0.8rem;
                            font-weight: 400;
                            line-height: 1.2;
                            color: #1c1d21;
                            margin: 0 0 0.5rem;
    
                            i {
                                margin-right: 0.25rem;
                            }
                        }
    
                        h6 {
                            font-size: 1rem;
                            font-weight: 600;
                            line-height: 1.2;
                            color: #1c1d21;
                            margin: 0 0 0.5rem;
                        }
    
                        p {
                            font-size: 0.9rem;
                            font-weight: 400;
                            line-height: 1.2;
                            color: #1c1d21;
                            margin: 0;
                        }
                    }
    
                    #{$dashboard}__importMainBox {
                        display: flex;
                        flex-direction: column;
    
                        width: calc(((100% / 3) * 2) - 1rem);
                        margin-right: 1rem;
    
                        @media (max-width: $mobileBig) {
                            width: 100%;
                            margin: 0 0 1rem;
                        }
    
                        background-color: $color-white;
                        border: 0.05rem solid rgba(0, 0, 0, 0.175);
                        border-radius: 0.375rem;
    
                        #{$dashboard}__importBox {
                            display: flex;
                            flex-direction: column;
    
                            height: 100%;
    
                            &--stepOne {
                            }
    
                            &--stepTwo {
                            }
    
                            &--stepThree {
                            }
    
                            #{$dashboard}__importHeader {
                                display: flex;
                                flex-direction: column;
    
                                padding: 20px;
                                //height: 66px;
                                border-bottom: 0.05rem solid rgba(0, 0, 0, 0.175);
                                //overflow: auto;
    
                                @media (max-width: $mobileBig) {
                                    padding: 5px;
                                    height: auto;
                                    overflow-x: auto;
                                }
    
                                // @media (max-width: $mobileBig) {
                                // 	padding: 0.75rem;
                                // 	height: 3.05rem;
                                // }
    
                                ul {
                                    display: flex;
                                    flex-direction: row;
    
                                    margin: 0;
                                    padding: 0;
    
                                    li {
                                        display: flex;
                                        flex-direction: row;
    
                                        position: relative;
    
                                        &.active {
                                            span {
                                                color: #4071eb;
                                            }
                                        }
    
                                        &:last-child {
                                            #{$dashboard}__importText {
                                                margin-right: 0;
    
                                                @media (max-width: $mobileBig) {
                                                    margin-right: 0.75rem;
                                                }
                                            }
                                        }
    
                                        span {
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;
                                        }
    
                                        #{$dashboard}__importNumber {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
    
                                            font-size: 0.7rem;
                                            font-weight: 400;
                                            padding: 0.1rem;
                                            width: 25px;
                                            height: 25px;
                                            background: #e6ecfc;
                                            border-radius: 25px;
                                            margin-right: 0.5rem;
                                        }
    
                                        #{$dashboard}__importText {
                                            font-size: 0.8rem;
                                            font-weight: 400;
                                            line-height: 1;
                                            color: #1c1d21;
                                            margin-right: 1.5rem;
                                            white-space: nowrap;
                                        }
    
                                        #{$dashboard}__importArrow {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
    
                                            width: 0.75rem;
                                            height: 0.75rem;
                                            position: absolute;
                                            top: 50%;
                                            right: 0.5rem;
                                            transform: translateY(-50%);
    
                                            i {
                                                font-size: 0.5rem;
                                            }
                                        }
                                    }
                                }
                            }
    
                            #{$dashboard}__importBody {
                                display: flex;
                                flex-direction: column;
    
                                padding: 1rem;
                                min-height: calc(100% - 7.35rem);
    
                                // @media (max-width: $mobileBig) {
                                // 	padding: 0.75rem;
                                // 	min-height: calc(100% - 6.45rem);
                                // }
    
                                p {
                                    display: flex;
                                    flex-direction: row;
    
                                    margin: 0 0 1rem;
                                    position: relative;
                                    padding-left: 1.25rem;
    
                                    &:last-child {
                                        margin: 0;
                                    }
    
                                    .fa-check {
                                        font-size: 0.8rem;
                                        position: absolute;
                                        top: 0.6rem;
                                        left: 0;
                                        transform: translateY(-50%);
                                    }
    
                                    a {
                                        display: inline-block;
                                        margin-left: 0.25rem;
    
                                        img {
                                            width: 0.75rem;
                                            margin-left: 0.25rem;
                                        }
                                    }
                                }
    
                                #{$dashboard}__importForm {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
    
                                    border: 0.05rem dashed #b3c6f7;
                                    width: 100%;
                                    height: auto;
                                    background: $color-white;
                                    border-radius: 0.2rem;
                                    box-sizing: border-box;
                                    padding: 1rem;
    
                                    @media (max-width: $mobileBig) {
                                        flex-direction: column;
    
                                        // padding: 0.75rem;
                                        height: auto;
                                    }
    
                                    #{$dashboard}__importFormImage {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
    
                                        width: 2.8rem;
                                        height: 2.8rem;
                                        margin-right: 0.7rem;
    
                                        img {
                                            width: 100%;
                                        }
    
                                        @media (max-width: $mobileBig) {
                                            margin: 0 0 0.8rem;
                                        }
                                    }
    
                                    #{$dashboard}__importFormDescription {
                                        display: flex;
                                        flex-direction: column;
                                        width: calc(100% - 3.5rem);
                                        text-align: center;
    
                                        #{$dashboard}__importFormTitle {
                                            display: flex;
                                            flex-direction: row;
    
                                            margin-bottom: 0.5rem;
    
                                            @media (max-width: $mobileBig) {
                                                flex-direction: column;
    
                                                margin: 0 0 0.5rem;
                                            }
    
                                            #{$dashboard}__importFormLeft {
                                                display: flex;
                                                flex-direction: column;
    
                                                font-size: 0.7rem;
                                                font-weight: 600;
                                                color: #1c1d21;
                                            }
    
                                            #{$dashboard}__importFormRight {
                                                display: flex;
                                                flex-direction: column;
    
                                                cursor: pointer;
                                                position: relative;
                                                margin-left: 0.25rem;
    
                                                @media (max-width: $mobileBig) {
                                                    margin: 0;
                                                }
    
                                                #{$dashboard}__importFormText {
                                                    font-size: 0.7rem;
                                                    font-weight: 400;
                                                    color: #0042e4;
                                                }
    
                                                #{$dashboard}__importFormTInput {
                                                    position: absolute;
                                                    top: 0;
                                                    left: 0;
                                                    width: 100%;
                                                    height: 100%;
                                                    opacity: 0;
                                                    z-index: 1;
                                                    cursor: pointer;
                                                }
                                            }
                                        }
    
                                        #{$dashboard}__importFormSubtitle {
                                            color: #696f80;
                                            font-size: 0.6rem;
                                            line-height: 1.2;
                                        }
                                    }
    
                                    &--column {
                                        flex-direction: column;
    
                                        border: none;
                                        padding: 0;
    
                                        #{$dashboard}__importFormImage {
                                            width: 12.5rem;
                                            height: auto;
                                            margin: 0 0 1rem;
                                            border-radius: 0.5rem;
                                            overflow: hidden;
                                        }
    
                                        #{$dashboard}__importFormDescription {
                                            #{$dashboard}__importFormTitle {
                                                justify-content: center;
                                                flex-direction: column;
    
                                                text-align: center;
    
                                                #{$dashboard}__importFormLeft {
                                                    font-size: 1rem;
                                                    margin: 0 0 0.5rem;
                                                }
    
                                                #{$dashboard}__importFormRight {
                                                    margin-left: 0;
    
                                                    #{$dashboard}__importFormText {
                                                        font-size: 0.8rem;
                                                        color: #1c1d21;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
    
                                #{$dashboard}__importProgressBarBox {
                                    width: 70%;
    
                                    #{$dashboard}__importProgressBar {
                                        width: 100%;
                                        background-color: #e0e0e0;
                                        padding: 0.15rem;
                                        border-radius: 0.5rem;
                                        box-shadow: inset 0 0.05rem 0.05rem rgba(0, 0, 0, 0.1);
                                        height: 10px;
                                        position: relative;
                                        overflow: hidden;
    
                                        #{$dashboard}__importProgressBarFill {
                                            display: block;
                                            height: 10px;
                                            background-color: $primary-color;
                                            border-radius: 0.5rem;
                                            position: absolute;
                                            top: 50%;
                                            width: 50%;
                                            transform: translateY(-50%);
                                            animation: progressbarSlider $progressbar-speed linear infinite;
                                        }
                                    }
                                }
                            }
    
                            #{$dashboard}__importFooter {
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
    
                                padding: 1rem;
                                height: 4.05rem;
    
                                // @media (max-width: $mobileBig) {
                                // 	padding: 0.75rem;
                                // 	height: 3.4rem;
                                // }
    
                                button {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
    
                                    padding: 0.3rem 1.5rem;
                                    font-size: 0.8rem;
                                    border-radius: 0.25rem;
                                    margin-left: 1rem;
    
                                    // @media (max-width: $mobileBig) {
                                    // 	padding: 0.3rem 0.755rem;
                                    // 	margin-left: 0.5rem;
                                    // }
    
                                    &:first-child {
                                        margin-left: 0;
                                    }
    
                                    &[disabled] {
                                        pointer-events: all;
                                        cursor: not-allowed;
                                    }
    
                                    &.btn-primary {
                                    }
                                }
                            }
                        }
                    }
    
                    #{$dashboard}__importSidebar {
                        display: flex;
                        flex-direction: column;
    
                        width: calc(((100% / 3) * 1) - 0.5rem);
                        border: 0.1rem solid rgba(0, 0, 0, 0.175);
                        background-color: $color-white;
                        padding: 1rem;
                        border-radius: 0.375rem;
    
                        @media (max-width: $mobileBig) {
                            width: 100%;
                            margin: 0;
                        }
    
                        #{$dashboard}__importSidebarHeader {
                            display: flex;
                            flex-direction: column;
    
                            padding-bottom: 1rem;
    
                            p {
                                font-size: 0.7rem;
                                font-weight: 400;
                                line-height: 1.5;
                                color: $color-black;
                                margin: 0;
                            }
                        }
    
                        #{$dashboard}__importSidebarBody {
                            display: flex;
                            flex-direction: column;
    
                            padding-bottom: 1rem;
    
                            table {
                                thead,
                                tbody {
                                    tr {
                                        th,
                                        td {
                                            font-size: 0.6rem;
                                            padding: 0.25rem;
                                        }
                                    }
                                }
                            }
    
                            #{$dashboard}__importSidebarTop {
                                display: flex;
                                flex-direction: row;
    
                                border: 0.05rem solid #d7dadf;
                                padding: 0.75rem;
                                border-radius: 0.4rem;
    
                                #{$dashboard}__importSidebarIcon {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
    
                                    padding: 0.6rem;
                                    width: 2.4rem;
                                    height: 2.4rem;
                                    background: #e6ecfc;
                                    border-radius: 0.2rem;
                                    margin-right: 0.5rem;
    
                                    i {
                                        font-size: 1.1rem;
                                        color: $primary-color;
                                    }
                                }
    
                                #{$dashboard}__importSidebarContent {
                                    display: flex;
                                    flex-direction: column;
    
                                    width: calc(100% - 1.3rem);
    
                                    #{$dashboard}__importSidebarCount {
                                        font-size: 0.9rem;
                                        font-weight: 700;
                                        line-height: 1.5;
                                        color: $color-black;
                                    }
    
                                    #{$dashboard}__importSidebarText {
                                        font-size: 0.7rem;
                                        font-weight: 400;
                                        line-height: 1.5;
                                        color: $color-black;
                                    }
                                }
                            }
    
                            #{$dashboard}__importSidebarBottom {
                                display: flex;
                                align-items: center;
                                justify-content: center;
    
                                margin-top: 1rem;
                                background-color: #f4f6f9;
                                border-radius: 0.4rem;
                                text-align: center;
                                padding: 0.75rem;
    
                                img {
                                    // width: 0.375rem;
                                    padding-right: 1rem;
                                }
                            }
                        }
    
                        #{$dashboard}__importSidebarFooter {
                            display: flex;
                            flex-direction: column;
    
                            p {
                                font-size: 0.7rem;
                                font-weight: 400;
                                line-height: 1.5;
                                color: $color-black;
                                margin: 0;
    
                                a {
                                    display: inline-block;
    
                                    i {
                                        font-size: 0.5rem;
                                    }
                                }
                            }
                        }
                    }
                }
    
                #{$dashboard}__profileSettingsMain {
                    display: flex;
                    flex-direction: column;
                    margin-top: 0;
    
                    #{$dashboard}__profileSettingsBox {
                        display: flex;
                        flex-direction: column;     
                        
                        &.offerUpload {
                            .customForm {
                                border: none;
                                padding: 10px 0 0 0;
                            }
                        }
    
                        // #{$dashboard}__profileSettingsAccordion {
                        // 	display: flex;
                        // 	flex-direction: column;
    
                        // 	#{$dashboard}__profileSettingsItem {
                        // 		display: flex;
                        // 		flex-direction: column;
    
                        // 		border: 0.1rem solid #50a3e5;
                        // 		border-radius: 0.75rem;
                        // 		margin: 0 0 3rem;
    
                        // 		&:last-child {
                        // 			margin-bottom: 0;
                        // 		}
    
                        // 		#{$dashboard}__profileSettingsHeader {
                        // 			display: flex;
                        // 			flex-direction: column;
    
                        // 			button {
                        // 				box-shadow: none !important;
                        // 				outline: none !important;
                        // 				border-radius: 0.75rem;
                        // 				color: #212529;
    
                        // 				&:not(.collapsed) {
                        // 					color: #212529;
                        // 				}
                        // 			}
                        // 		}
    
                        // 		#{$dashboard}__profileSettingsBody {
                        // 		}
                        // 	}
                        // }
    
                        .customForm.customForm_2 .form-group {
                            margin-bottom: 0.8rem !important;
    
                            label {
                                font-size: 0.8rem;
                            }
    
                            .css-13cymwt-control {
                                border-radius: $inp_radius;
                                // min-height: 38px !important;
                            }
    
                            .css-1fdsijx-ValueContainer {
                                padding: 0.4rem 0.9rem !important;
                                font-size: 1rem !important;
                            }
                            .css-1jqq78o-placeholder {
                                margin: 0;
                                font-size: 0.8rem;
                                color: #191a1a;
                            }
                            .css-qbdosj-Input {
                                margin: 0;
                                padding: 0;
                            }
                            .css-1xc3v61-indicatorContainer {
                                padding: 0.215rem !important;
                            }
                        }
    
                        .voucher_box_row {
                            display: flex;
                            align-items: center;
    
                            .form-group {
                                margin-bottom: 0.8rem;
                            }
                        }
    
                        .profileSettingsBoxCSP {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 1rem;
    
                            @media screen and (max-width: $mobileBig) {
                                flex-direction: column;
                            }
    
                            .profileSettingsmerchantbox {
                                width: 50%;
                                display: flex;
    
                                @media screen and (max-width: $mobileBig) {
                                    width: 100%;
                                    margin-bottom: 0.8rem;
                                }
                            }
    
                            // .customForm {
                            //     width: 100%;
                            // }
    
                            a.btn {
                                width: fit-content;
                                float: right;
    
                                i {
                                    margin-left: 0.5rem;
                                }
                            }
    
                            .profileSettingsqrcodebox {
                                @media screen and (max-width: $mobileBig) {
                                    width: 100%;
                                    display: flex;
                                    justify-content: space-evenly;
                                }
    
                                div {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
    
                                    @media screen and (max-width: $mobileBig) {
                                        flex-wrap: wrap;
                                    }
    
                                    p {
                                        margin-bottom: 0;
                                        margin-right: 15px;
                                        font-weight: 600;
    
                                        @media screen and (max-width: $mobileBig) {
                                            margin-bottom: 10px;
                                        }
                                    }
    
                                    a {
                                        padding: 10px;
                                        border-radius: 5px;
                                        background-color: var(--bs-link-color);
                                        color: #FFF;
    
                                        i {
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }
    
                        .profileSettingsBoxCSPdetails {
                            display: flex;
                            justify-content: flex-end;
                            align-items: baseline;
    
                            p {
                                margin-right: 15px;
                                color: dodgerblue;
                            }
    
                            a {
                                i {
                                    margin-left: 10px;
                                }
                            }
                        }
    
                        .customForm {
                            display: flex;
                            flex-direction: column;
    
                            padding: 1rem;
                            background-color: $primary-bgColor;
                            border-radius: 0.5rem;
                            border: 0.05rem solid #50a3e5;
    
                            &.linkBlock {
                                padding: 12px;
                                width: 100%;
                                border-radius: $inp_radius;
                            }
    
                            .form-group {
                                display: flex;
                                flex-direction: column;
    
                                margin: 0 0 1.5rem;
    
                                &.withIcon {
                                    position: relative;
    
                                    .form-control {
                                        padding-right: 2rem;
                                    }
                                }
    
                                label {
                                    font-size: 0.9rem;
                                    font-weight: 400;
                                    line-height: 1.2;
                                    color: #191a1a;
                                    margin: 0 0 0.5rem;
    
                                    // @media (max-width: $mobileBig) {
                                    // 	font-size: 0.7rem;
                                    // }
                                }
    
                                .form-control {
                                    padding: 0.45rem 0.9rem;
                                    border-radius: $inp_radius;
                                    outline: none !important;
                                    box-shadow: none !important;
                                    font-size: 0.8rem;
    
                                    // @media (max-width: $mobileBig) {
                                    // 	padding: 0.45rem 0.5rem;
                                    // }
                                }
    
                                .css-319lph-ValueContainer {
                                    padding: 0.45rem 0.9rem !important;
                                }
    
                                .inputIcon {
                                    position: absolute;
                                    right: 0.5rem;
                                    top: 2.05rem;
                                    width: 1.25rem;
    
                                    // @media (max-width: $mobileBig) {
                                    // 	top: 1.75rem;
                                    // }
                                }
                            }
    
                            .button-section {
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
    
                                @media (max-width: $mobileBig) {
                                    flex-direction: column;
                                }
    
                                .btn {
                                    margin-left: 0.75rem;
    
                                    @media (max-width: $mobileBig) {
                                        width: 100%;
                                        margin: 0 0 1rem;
                                    }
    
                                    &:first-child {
                                        margin-left: 0;
                                    }
    
                                    &:last-child {
                                        @media (max-width: $mobileBig) {
                                            margin: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
    
                    .contactnovussupport {
                        display: flex;
                        justify-content: end;
    
                        i {
                            margin-left: 5px;
                        }
                    }
                }
    
                #{$dashboard}__helpFAQsList {
                    display: flex;
                    flex-direction: column;
    
                    #{$dashboard}__helpFAQsListHeading {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
    
                        text-align: center;
                        margin: 0 0 1.5rem;
    
                        h3 {
                            font-size: 1.2rem;
                            font-weight: 600;
                            line-height: 1.2;
                            color: $color-black;
                            margin: 0 0 0.5rem;
                        }
    
                        p {
                            font-size: 1rem;
                            font-weight: 400;
                            line-height: 1.5;
                            color: $color-black;
                            margin: 0;
                        }
                    }
    
                    #{$dashboard}__helpFAQsListForm {
                        display: flex;
                        align-items: center;
                        justify-content: center;
    
                        input {
                            outline: none !important;
                            box-shadow: none !important;
                            width: 100%;
                            max-width: 25rem;
                            height: 2.5rem;
                            padding: 0.45rem 0.75rem;
                            border-radius: 0.75rem;
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }
    
                        button {
                            outline: none !important;
                            box-shadow: none !important;
                            height: 2.5rem;
                            padding: 0.75rem 0.75rem;
                            border-radius: 0.75rem;
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }
                    }
    
                    #{$dashboard}__helpFAQsListCards {
                        display: flex;
                        flex-wrap: wrap;
    
                        #{$dashboard}__helpFAQsListCard {
                            display: flex;
                            flex-direction: column;
    
                            width: calc((100% / 4) - 0.75rem);
                            padding: 1.25rem;
                            margin: 1.5rem 0 0;
                            background-color: $primary-bgColor;
                            border: 0.05rem solid #50a3e5;
                            border-radius: 0.375rem;
                            margin-right: 1rem;
    
                            &:nth-child(4n + 4) {
                                margin-right: 0;
                            }
    
                            @media (max-width: $desktop - 1) {
                                width: calc((100% / 3) - 0.7rem);
                                margin-right: 0.51rem;
    
                                &:nth-child(4n + 4) {
                                    margin-right: 0.51rem;
                                }
    
                                &:nth-child(3n + 3) {
                                    margin-right: 0;
                                }
                            }
    
                            @media (max-width: $desktopSmall - 1) {
                                width: calc((100% / 2) - 0.5rem);
                                margin-right: 1rem;
    
                                &:nth-child(3n + 3) {
                                    margin-right: 1rem;
                                }
    
                                &:nth-child(2n + 2) {
                                    margin-right: 0;
                                }
                            }
    
                            @media (max-width: $mobileBig - 1) {
                                width: 100%;
                                margin-right: 0 !important;
                            }
    
                            @media (max-width: $mobile - 1) {
                                padding: 0.75rem;
                            }
    
                            #{$dashboard}__helpFAQsListHeader {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
    
                                margin: 0 0 0.5rem;
    
                                #{$dashboard}__helpFAQsListLeft {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
    
                                    h5 {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;
    
                                        font-size: 1rem;
                                        font-weight: 600;
                                        line-height: 1.2;
                                        color: #191a1a;
                                        margin: 0;
    
                                        // @media (max-width: $mobile - 1) {
                                        // 	font-size: 0.8rem;
                                        // }
                                    }
                                }
                            }
    
                            #{$dashboard}__helpFAQsListBody {
                                display: flex;
                                flex-direction: column;
    
                                width: 100%;
                                margin: 0 0 0.75rem;
    
                                // @media (max-width: $mobileBig) {
                                // 	margin: 0 0 0.5rem;
                                // }
    
                                ul {
                                    display: flex;
                                    flex-direction: column;
    
                                    margin: 0;
                                    padding: 0;
    
                                    li {
                                        display: block;
                                        font-size: 0.7rem;
                                        font-weight: 400;
                                        line-height: 1.5;
                                        color: #191a1a;
                                        margin: 0 0 0.25rem;
                                        padding-left: 0.75rem;
                                        position: relative;
    
                                        &:last-child {
                                            margin: 0;
                                        }
    
                                        &::after {
                                            content: "";
                                            position: absolute;
                                            top: 0.51rem;
                                            left: 0;
                                            transform: translateY(-50%);
                                            width: 0.25rem;
                                            height: 0.25rem;
                                            background-color: $primary-color;
                                            border-radius: 50%;
                                        }
                                    }
                                }
                            }
    
                            #{$dashboard}__helpFAQsListFooter {
                                display: flex;
                                flex-direction: column;
    
                                a {
                                    display: inline-block;
                                    font-size: 0.6rem;
                                    font-weight: 700;
                                    line-height: 1.5;
                                    color: $primary-color;
    
                                    @media (max-width: $mobileBig) {
                                        max-width: none;
    
                                        &:first-child {
                                            margin: 0 0 0.5rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                #{$dashboard}__helpFAQsDetails {
                    display: flex;
                    flex-direction: column;
    
                    #{$dashboard}__helpFAQsDetailsHeader {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
    
                        margin: 0 0 2rem;
    
                        @media (max-width: $desktopSmall - 1) {
                            flex-direction: column;
                        }
    
                        #{$dashboard}__helpFAQsDetailsHeading {
                            display: flex;
                            flex-direction: column;
    
                            max-width: 50%;
    
                            @media (max-width: $desktopSmall - 1) {
                                max-width: 100%;
                                margin: 0 0 1.5rem;
                            }
    
                            @media (max-width: $mobileBig - 1) {
                                margin: 0 0 1rem;
                            }
    
                            h3 {
                                font-size: 1.4rem;
                                font-weight: 600;
                                line-height: 1.2;
                                color: $color-black;
                                margin: 0;
                            }
                        }
    
                        #{$dashboard}__helpFAQsDetailsSearch {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
    
                            position: relative;
                            width: 50%;
    
                            @media (max-width: $desktopSmall - 1) {
                                justify-content: center;
    
                                width: 100%;
                            }
    
                            > input {
                                outline: none !important;
                                box-shadow: none !important;
                                width: 100%;
                                max-width: 25rem;
                                height: 2.5rem;
                                padding-right: 40px !important;
                                border-radius: 0;
                                background: transparent url("../Image/searchIcon.png") no-repeat right 15px center / 20px;
    
                                @media screen and (max-width: $mobileBig) {
                                    max-width: 100%;
                                }
                            }
    
                            ul {
                                position: absolute;
                                top: 49px;
                                right: 0;
                                width: 500px;
                                height: auto;
                                padding: 15px;
                                margin: 0;
                                background-color: $color-white;
                                border: 1px solid #86b7fe;
                                z-index: 1;
                                max-height: 350px;
                                overflow: auto;
    
                                @media screen and (max-width: $mobileBig) {
                                    top: 40px;
                                    width: 100%;
                                    padding: 10px;
                                    max-height: 200px;
                                }
    
                                li {
                                    display: flex;
                                    flex-direction: column;
    
                                    margin: 0 0 0px;
    
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
    
                                    button {
                                        background: none;
                                        border: none;
                                        border-radius: 7.5px;
                                        text-align: left;
                                        padding: 10px;
    
                                        @media screen and (max-width: $mobileBig) {
                                            padding: 0.8rem;
                                            border-bottom: 1px solid #efefef;
                                            border-radius: 0;
                                        }
    
                                        &:hover {
                                            background-color: #eeeeee;
                                        }
                                    }
    
                                    &:last-child {
                                        button {
                                            &:nth-last-child(1) {
                                                border-bottom: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
    
                    #{$dashboard}__helpFAQsDetailsBody {
                        display: flex;
                        flex-direction: row;
    
                        @media (max-width: $desktopSmall - 1) {
                            flex-direction: column;
                        }
    
                        #{$dashboard}__helpFAQsDetailsLeft {
                            display: flex;
                            flex-direction: column;
    
                            width: 15rem;
    
                            @media (max-width: $desktopSmall - 1) {
                                width: 100%;
                                margin: 0 0 1.5rem;
                            }
    
                            #{$dashboard}__helpFAQsDetailsTop {
                                display: flex;
                                align-items: flex-start;
                                justify-content: flex-start;
    
                                margin: 0 0 1rem;
    
                                #{$dashboard}__helpFAQsDetailsBack {
                                    display: flex;
                                    flex-direction: column;
    
                                    width: 1rem;
                                    height: 1rem;
                                    margin-right: 0.5rem;
    
                                    a {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
    
                                        width: 100%;
                                        height: 100%;
                                        color: $color-black;
    
                                        i {
                                            font-size: 1rem;
                                        }
                                    }
                                }
    
                                #{$dashboard}__helpFAQsDetailsHeading {
                                    display: flex;
                                    flex-direction: column;
    
                                    width: calc(100% - 1.5rem);
    
                                    p {
                                        font-size: 0.75rem;
                                        font-weight: 400;
                                        line-height: 1.2;
                                        color: $color-black;
                                        margin: 0 0 0.5rem;
                                    }
    
                                    h6 {
                                        font-size: 1rem;
                                        font-weight: 600;
                                        line-height: 1.2;
                                        color: $color-black;
                                        margin: 0;
                                    }
                                }
                            }
    
                            #{$dashboard}__helpFAQsDetailsTabs {
                                display: flex;
                                flex-direction: column;
    
                                ul.nav.nav-tabs {
                                    display: flex;
                                    flex-direction: column;
    
                                    margin: 0;
                                    padding: 0;
                                    border: none;
    
                                    @media only screen and (max-width: $mobileBig) {
                                        display: block;
                                        overflow-x: scroll;
                                        white-space: nowrap;
                                    }
    
                                    li.nav-item {
                                        display: flex;
                                        flex-direction: row;
                                        margin: 0;
    
                                        @media only screen and (max-width: $mobileBig) {
                                            display: inline-block;
                                        }
    
                                        button.nav-link {
                                            display: inline-block;
                                            font-size: 0.8rem;
                                            font-weight: 400;
                                            line-height: 1.2;
                                            color: #191a1a;
                                            text-align: left;
                                            padding: 0.6rem 0;
                                            margin: 0;
                                            border-radius: 0;
                                            box-shadow: none;
                                            outline: none !important;
                                            border: none !important;
                                            transition: all 0.5s;
    
                                            @media only screen and (max-width: $mobileBig) {
                                                padding: 0.8rem;
                                                transition: none;
                                            }
    
                                            &:hover,
                                            &.active {
                                                font-weight: 700;
                                                background-color: transparent;
                                                border: none;
                                                border-radius: 0;
    
                                                @media only screen and (max-width: $mobileBig) {
                                                    font-weight: 500;
                                                    background-color: #ffffff;
                                                    border-top-left-radius: 0.5rem;
                                                    border-top-right-radius: 0.5rem;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
    
                        #{$dashboard}__helpFAQsDetailsRight {
                            display: flex;
                            flex-direction: column;
    
                            width: calc(100% - 15rem);
                            background-color: $primary-bgColor;
                            padding: 2rem;
                            border: 0.05rem solid #d2d3d3;
    
                            @media (max-width: $desktopSmall - 1) {
                                width: 100%;
                            }
    
                            @media (max-width: $mobileBig) {
                                padding: 1.25rem;
                            }
    
                            // @media (max-width: $mobileBig - 1) {
                            // 	padding: 1.5rem;
                            // }
    
                            // @media (max-width: $mobile - 1) {
                            // 	padding: 0.75rem;
                            // }
    
                            #{$dashboard}__helpFAQsDetailsContent {
                                display: flex;
                                flex-direction: column;
    
                                + #{$dashboard}__helpFAQsDetailsContent {
                                    margin-top: 2rem;
                                }
    
                                h4 {
                                    font-size: 1.2rem;
                                    font-weight: 600;
                                    line-height: 1.2;
                                    color: #191a1a;
                                    margin: 0 0 1rem;
                                }
    
                                p {
                                    font-size: 0.8rem;
                                    font-weight: 400;
                                    // line-height: 1.4;
                                    line-height: 1.54;
                                    color: #191a1a;
                                    margin: 0 0 1rem;
    
                                    &:last-child {
                                        margin: 0;
                                    }
                                }
    
                                ul {
                                    display: flex;
                                    flex-direction: column;
    
                                    padding: 0;
                                    margin: 0;
    
                                    li {
                                        display: block;
                                        line-height: 1.4;
                                        margin: 0 0 1rem;
                                        padding-left: 1rem;
                                        position: relative;
                                        color: #191a1a;
    
                                        &:last-child {
                                            margin: 0;
                                        }
    
                                        &::after {
                                            content: "";
                                            position: absolute;
                                            top: 12px;
                                            left: 0;
                                            transform: translateY(-50%);
                                            width: 5px;
                                            height: 5px;
                                            background-color: #191a1a;
                                            border-radius: 50%;
                                        }
    
                                        &.listHeading {
                                            display: block;
                                            z-index: 0;
                                            padding-left: 1.25rem;
    
                                            &::before {
                                                content: "";
                                                position: absolute;
                                                top: 9px;
                                                left: 0;
                                                transform: translateY(-50%);
                                                border-left: 3.25px solid $color-white;
                                                border-top: 7.5px solid transparent;
                                                border-bottom: 7.5px solid transparent;
                                                z-index: 2;
                                            }
    
                                            &::after {
                                                content: "";
                                                top: 9px;
                                                background-color: transparent;
                                                border-radius: 0;
                                                border-left: 0.75rem solid #191a1a;
                                                border-top: 7.5px solid transparent;
                                                border-bottom: 7.5px solid transparent;
                                                z-index: 1;
    
                                                // @media (max-width: $mobileBig) {
                                                // 	top: 0.5rem;
                                                // }
                                            }
                                        }
    
                                        ul {
                                            margin-top: 1rem;
    
                                            li {
                                                display: block;
    
                                                &::after {
                                                    content: "";
                                                    // background-color: transparent;
                                                    border: 0.05rem solid #191a1a;
                                                }
    
                                                ul {
                                                    margin-top: 1rem;
    
                                                    li {
                                                        display: block;
    
                                                        &::after {
                                                            content: "";
                                                            background-color: #191a1a;
                                                            border: none;
                                                            border-radius: 0;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                #{$dashboard}__paginationBox {
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    ul {
                        display: flex;
                        flex-direction: row;
    
                        padding: 0;
                        margin: 0;
    
                        li {
                            display: flex;
                            flex-direction: row;
    
                            margin: 0;
                            margin-right: 0.5rem;
    
                            &:last-child {
                                margin-right: 0;
                            }
    
                            a {
                                display: inline-block;
                            }
                        }
                    }
                }
                #{$dashboard}__downloadButton {
                    display: flex;
                    align-items: center;
                    justify-content: right;
                }
    
                #{$dashboard}__financialAccountMain {
                    display: flex;
                    flex-direction: column;
    
                    #{$dashboard}__financialAccountCard {
                        display: flex;
                        flex-direction: column;
    
                        padding: 1.25rem;
                        margin: 0 0 1.5rem;
                        background-color: $primary-bgColor;
                        border: 0.1rem solid #50a3e5;
    
                        &:last-child {
                            margin-bottom: 0;
                        }
    
                        // @media (max-width: $mobile - 1) {
                        // 	padding: 0.75rem;
                        // }
    
                        #{$dashboard}__financialAccountProfile {
                            display: flex;
                            flex-direction: row;
    
                            #{$dashboard}__financialAccountImage {
                                display: flex;
                                align-items: center;
                                justify-content: center;
    
                                width: 12%;
                                max-width: 10rem;
                                border-right: 0.05rem solid #e9e9e9;
    
                                img {
                                    width: 50%;
                                    height: auto;
                                    object-fit: cover;
                                }
                            }
    
                            #{$dashboard}__financialAccountDetails {
                                display: flex;
                                flex-direction: column;
    
                                width: calc(100% - 10rem);
    
                                #{$dashboard}__financialAccountTop {
                                    display: flex;
                                    flex-direction: column;
    
                                    margin: 0 0 1rem;
                                    padding-left: 1.5rem;
    
                                    h2 {
                                        font-size: 1rem;
                                        font-weight: 400;
                                        line-height: 1.2;
                                        color: #282828;
                                        margin: 0;
                                    }
                                }
    
                                #{$dashboard}__financialAccountBottom {
                                    display: flex;
                                    align-items: flex-start;
                                    justify-content: flex-start;
    
                                    #{$dashboard}__financialAccountLeft,
                                    #{$dashboard}__financialAccountRight {
                                        display: flex;
                                        flex-direction: column;
    
                                        min-width: 25%;
                                        padding-right: 1rem;
                                        border-right: 0.05rem solid #e9e9e9;
                                        padding-left: 1.5rem;
    
                                        ul {
                                            display: flex;
                                            flex-direction: column;
    
                                            padding: 0;
                                            margin: 0;
    
                                            li {
                                                display: block;
    
                                                font-size: 0.8rem;
                                                font-weight: 400;
                                                line-height: 1.2;
                                                color: #282828;
                                                margin: 0 0 0.5rem;
    
                                                &:last-child {
                                                    margin-bottom: 0;
                                                }
                                            }
                                        }
                                    }
    
                                    #{$dashboard}__financialAccountRight {
                                        border: none;
                                    }
                                }
                            }
                        }
    
                        #{$dashboard}__financialAccountPointsTabs {
                            display: flex;
                            flex-direction: column;
    
                            ul.nav.nav-tabs {
                                display: flex;
                                flex-direction: row;
    
                                border: none;
                                margin: 0 0 1.5rem;
    
                                li.nav-item {
                                    display: flex;
                                    flex-direction: column;
    
                                    button.nav-link {
                                        display: flex;
                                        flex-direction: column;
    
                                        font-size: 1rem;
                                        line-height: 1.2;
                                        color: #282828;
                                        border: none;
                                        border-bottom: 0.15rem solid #cfcfcf;
                                        margin: 0;
                                        background-color: transparent;
                                        padding: 1rem 3rem;
    
                                        &.active {
                                            border-color: $primary-color;
                                            color: $primary-color;
                                        }
                                    }
                                }
                            }
    
                            #{$dashboard}__financialAccountPoints {
                                display: flex;
                                flex-direction: column;
    
                                #{$dashboard}__financialAccountHeading {
                                    display: flex;
                                    flex-direction: column;
    
                                    margin: 0 0 1rem;
    
                                    h3 {
                                        font-size: 1rem;
                                        font-weight: 400;
                                        line-height: 1.2;
                                        color: #282828;
                                        margin: 0;
                                    }
                                }
    
                                #{$dashboard}__financialAccountBox {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    flex-direction: column;
    
                                    background-color: #f3f3f3;
                                    padding: 1.5rem;
                                    border-radius: 0.375rem;
                                    margin: 0 0 2rem;
    
                                    h4 {
                                        font-size: 0.9rem;
                                        font-weight: 400;
                                        line-height: 1.2;
                                        color: #282828;
                                        margin: 0 0 0.5rem;
                                    }
    
                                    h2 {
                                        font-size: 1.2rem;
                                        font-weight: 800;
                                        line-height: 1.2;
                                        color: $primary-color;
                                        margin: 0;
                                    }
                                }
    
                                .form-group {
                                    display: flex;
                                    flex-direction: column;
    
                                    margin: 0 0 2rem;
    
                                    @media (max-width: $mobileBig) {
                                        margin: 0 0 1rem;
                                    }
                                    label {
                                        display: block;
                                        margin: 0 0 0.5rem;
                                    }
    
                                    button {
                                        padding: 0.375rem 0.75rem;
                                        margin-top: 1.6rem;
    
                                        @media (max-width: $mobileBig) {
                                            margin-top: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
    
                    .customTable {
                        $customTable: ".customTable";
    
                        display: flex;
                        flex-direction: column;
    
                        // #{$customTable}__header {
                        // 	display: flex;
                        // 	align-items: center;
                        // 	justify-content: space-between;
    
                        // 	margin-bottom: 4rem;
    
                        // 	@media (max-width: $mobileBig) {
                        // 		flex-direction: column;
    
                        // 		margin-bottom: 2rem;
                        // 	}
    
                        // 	#{$customTable}__left {
                        // 		display: flex;
                        // 		flex-direction: column;
    
                        // 		width: 25%;
    
                        // 		@media (max-width: $mobileBig) {
                        // 			width: 100%;
                        // 			margin-bottom: 2rem;
                        // 		}
    
                        // 		#{$customTable}__showingRecords {
                        // 			display: flex;
                        // 			align-items: center;
                        // 			justify-content: flex-start;
    
                        // 			width: 100%;
    
                        // 			label {
                        // 				color: #191a1a;
                        // 				display: block;
                        // 				margin: 0 1rem 0 0;
                        // 			}
    
                        // 			.form-control {
                        // 				padding: 1.2rem 1.8rem;
                        // 				border-radius: 0.75rem;
                        // 				outline: none !important;
                        // 				box-shadow: none !important;
                        // 				width: auto;
                        // 			}
                        // 		}
                        // 	}
    
                        // 	#{$customTable}__right {
                        // 		display: flex;
                        // 		flex-direction: column;
    
                        // 		width: 75%;
    
                        // 		#{$customTable}__searchFilter {
                        // 			// display: flex;
                        // 			// align-items: center;
                        // 			// justify-content: flex-end;
    
                        // 			width: 100%;
    
                        // 			@media (max-width: $mobileBig) {
                        // 				justify-content: center;
                        // 				flex-direction: column;
    
                        // 				width: 100%;
                        // 			}
    
                        // 			.form-group {
                        // 				display: flex;
                        // 				flex-direction: column;
    
                        // 				margin: 0;
    
                        // 				label {
                        // 					color: #191a1a;
                        // 					display: block;
                        // 					margin: 0;
                        // 					margin-bottom: 1rem;
    
                        // 					@media (max-width: $mobileBig) {
                        // 						margin-right: 0;
                        // 					}
                        // 				}
    
                        // 				.form-control {
                        // 					padding: 1.2rem 1.8rem;
                        // 					border-radius: 0.75rem;
                        // 					outline: none !important;
                        // 					box-shadow: none !important;
                        // 					width: auto;
    
                        // 					@media (max-width: $mobileBig) {
                        // 						width: 100%;
                        // 					}
                        // 				}
    
                        // 				.btn {
                        // 					padding: 13.0.5rem 1.2rem;
                        // 				}
                        // 			}
                        // 		}
                        // 	}
                        // }
    
                        #{$customTable}__body {
                            display: flex;
                            flex-direction: column;
    
                            margin-bottom: 1rem;
    
                            .table-responsive {
                                .table {
                                    thead {
                                        tr {
                                            th {
                                            }
                                        }
                                    }
    
                                    tbody {
                                        tr {
                                            td {
                                            }
                                        }
                                    }
                                }
                            }
                        }
    
                        #{$customTable}__footer {
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;
    
                            @media (max-width: $mobileBig) {
                                align-items: center;
                                flex-direction: column;
                            }
    
                            #{$customTable}__left {
                                display: flex;
                                flex-direction: column;
    
                                @media (max-width: $mobileBig) {
                                    margin-bottom: 1rem;
                                }
    
                                #{$customTable}__totalTransactions {
                                    display: flex;
                                    flex-direction: column;
    
                                    p {
                                        font-size: 0.8rem;
                                        font-weight: 400;
                                        color: $color-black;
                                        margin: 0;
    
                                        span {
                                            display: inline-block;
                                            font-weight: 700;
                                        }
                                    }
                                }
                            }
    
                            #{$customTable}__right {
                                display: flex;
                                flex-direction: column;
    
                                #{$customTable}__top {
                                    display: flex;
                                    flex-direction: column;
    
                                    margin-bottom: 1rem;
    
                                    #{$customTable}__totalPages {
                                        display: flex;
                                        flex-direction: column;
    
                                        p {
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-end;
    
                                            font-size: 0.8rem;
                                            font-weight: 400;
                                            color: $color-black;
                                            margin: 0;
    
                                            @media (max-width: $mobile - 1) {
                                                justify-content: center;
                                            }
    
                                            .form-control {
                                                border-radius: 0.375rem;
                                                outline: none !important;
                                                box-shadow: none !important;
                                                width: 30%;
                                                margin: 0 0.25rem;
                                                text-align: left;
                                            }
    
                                            span {
                                                display: inline-block;
                                                font-weight: 700;
                                                margin-left: 0.25rem;
                                            }
                                        }
                                    }
                                }
    
                                #{$customTable}__bottom {
                                    display: flex;
                                    flex-direction: column;
    
                                    #{$customTable}__buttons {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-end;
    
                                        span {
                                            margin-right: 0.5rem;
    
                                            &[disabled] {
                                                cursor: default;
                                                opacity: 0.7;
    
                                                &:hover {
                                                    border-color: #0d6efd;
                                                    color: #0d6efd;
                                                    background-color: transparent;
                                                }
                                            }
    
                                            &:last-child {
                                                margin: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                #{$dashboard}__customerViewMain {
                    display: flex;
                    flex-direction: column;
    
                    #{$dashboard}__customerViewCard {
                        display: flex;
                        flex-direction: column;
    
                        padding: 1.25rem;
                        margin: 0 0 1.5rem;
                        background-color: $primary-bgColor;
                        // border: 0.05rem solid #50a3e5;
    
                        &:last-child {
                            margin-bottom: 0;
                        }
    
                        .customer_group_form {
                            display: flex;
                            justify-content: end;
                        }
    
                        // @media (max-width: $mobile - 1) {
                        // 	padding: 0.75rem;
                        // }
    
                        .form-group{
    
                            label{
                                font-weight: 700;
                                margin-bottom: 0.5rem;
                            }
    
                            &.cr_p{
                                label{
                                    font-weight: 700;
                                }
    
                                p{
                                    font-size: 20px;
                                    color: #2e2a23;;
                                }
                            }
                        }
    
                        #{$dashboard}__customerViewProfile {
                            display: flex;
                            flex-direction: row;
    
                            @media (max-width: $mobileBig) {
                                flex-direction: column;
                            }
    
                            #{$dashboard}__customerViewImage {
                                display: flex;
                                align-items: center;
                                justify-content: center;
    
                                width: 12%;
                                max-width: 10rem;
                                border-right: 0.05rem solid #e9e9e9;
    
                                @media (max-width: $mobileBig) {
                                    display: none;
                                }
    
                                img {
                                    width: 50%;
                                    height: auto;
                                    object-fit: cover;
                                }
                            }
    
                            #{$dashboard}__customerViewDetails {
                                display: flex;
                                flex-direction: column;
    
                                width: calc(100% - 10rem);
    
                                @media (max-width: $mobileBig) {
                                    width: 100%;
                                }
    
                                #{$dashboard}__customerViewTop {
                                    display: flex;
                                    flex-direction: column;
    
                                    margin: 0 0 1rem;
                                    padding-left: 1.5rem;
    
                                    h2 {
                                        font-size: 1rem;
                                        font-weight: 700;
                                        line-height: 1.2;
                                        color: #282828;
                                        margin: 0;
                                    }
                                }
    
                                #{$dashboard}__customerViewBottom {
                                    display: flex;
                                    align-items: flex-start;
                                    justify-content: flex-start;
    
                                    @media (max-width: $mobileBig) {
                                        flex-direction: column;
                                    }
    
                                    #{$dashboard}__customerViewLeft,
                                    #{$dashboard}__customerViewRight {
                                        display: flex;
                                        flex-direction: column;
    
                                        min-width: 25%;
                                        padding-right: 1rem;
                                        border-right: 0.05rem solid #e9e9e9;
                                        padding-left: 1.5rem;
    
                                        @media (max-width: $mobileBig) {
                                            border-right: none;
                                        }
    
                                        ul {
                                            display: flex;
                                            flex-direction: column;
    
                                            padding: 0;
                                            margin: 0;
    
                                            li {
                                                display: block;
    
                                                font-size: 0.8rem;
                                                font-weight: 400;
                                                line-height: 1.2;
                                                color: #282828;
                                                margin: 0 0 0.5rem;
    
                                                @media (max-width: $mobileBig) {
                                                    margin-bottom: 10px;
                                                }
    
                                                &:last-child {
                                                    margin-bottom: 0;
                                                    @media (max-width: $mobileBig) {
                                                        margin-bottom: 10px;
                                                    }
                                                }
                                            }
                                        }
                                    }
    
                                    #{$dashboard}__customerViewRight {
                                        border: none;
                                    }
                                }
                            }
                        }
    
                        #{$dashboard}__customerViewPointsTabs {
                            display: flex;
                            flex-direction: column;
    
                            ul.nav.nav-tabs {
                                display: flex;
                                flex-direction: row;
    
                                border: none;
                                margin: 0 0 1.5rem;
    
                                li.nav-item {
                                    display: flex;
                                    flex-direction: column;
    
                                    button.nav-link {
                                        display: flex;
                                        flex-direction: column;
    
                                        font-size: 1rem;
                                        line-height: 1.2;
                                        color: #282828;
                                        border: none;
                                        border-bottom: 0.15rem solid #cfcfcf;
                                        margin: 0;
                                        background-color: transparent;
                                        padding: 1rem 3rem;
    
                                        &.active {
                                            border-color: $primary-color;
                                            color: $primary-color;
                                        }
                                    }
                                }
                            }
    
                            #{$dashboard}__customerViewTimeline {
                                display: flex;
                                align-items: flex-start;
                                flex-direction: column;
    
                                position: relative;
                                z-index: 0;
                                padding-left: 3.5rem;
    
                                &::before {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 2rem;
                                    width: 0.05rem;
                                    height: 100%;
                                    border-left: 0.1rem dashed #cfcfcf;
                                    z-index: 1;
                                }
    
                                #{$dashboard}__customerViewBox {
                                    display: flex;
                                    flex-direction: column;
    
                                    // background-color: $color-white;
                                    padding: 0.5rem;
                                    margin: 0 0 2rem;
                                    // border: 0.05rem solid #cfcfcf;
                                    min-width: 1.25rem;
                                    // box-shadow: 0 0 1rem 0.15rem rgba(#2b1c03, 0.08);
                                    position: relative;
                                    z-index: 1;
    
                                    &:last-child {
                                        margin: 0;
                                    }
    
                                    // &::before {
                                    // 	content: "";
                                    // 	position: absolute;
                                    // 	top: 1rem;
                                    // 	left: -0.05rem;
                                    // 	width: 1rem;
                                    // 	height: 1rem;
                                    // 	transform: translateX(-50%) rotate(45deg);
                                    // 	background-color: $color-white;
                                    // 	border: 0.05rem solid #cfcfcf;
                                    // 	border-right: none;
                                    // 	border-top: none;
                                    // 	z-index: 1;
                                    // }
    
                                    .timelineIcon {
                                        display: block;
                                        position: absolute;
                                        top: 0.4rem;
                                        left: -1.5rem;
                                        transform: translateX(-50%);
                                        background-color: $color-white;
                                        border: 0.05rem solid #cfcfcf;
                                        width: 1.25rem;
                                        height: 1.25rem;
                                        border-radius: 50%;
                                        z-index: 2;
    
                                        &::before {
                                            content: "";
                                            position: absolute;
                                            top: 50%;
                                            left: 50%;
                                            transform: translate(-50%, -50%);
                                            width: 0.625rem;
                                            height: 0.625rem;
                                            background-color: #cfcfcf;
                                            border-radius: 50%;
                                            z-index: 1;
                                            display: none;
                                        }
    
                                        &.active {
                                            border-color: $primary-color;
    
                                            &::before {
                                                background-color: $primary-color;
                                                display: block;
                                            }
                                        }
                                    }
    
                                    p {
                                        font-size: 0.8rem;
                                        font-weight: 400;
                                        line-height: 1.2;
                                        color: #9c9c9c;
                                        margin: 0 0 0.5rem;
                                    }
    
                                    h4 {
                                        font-size: 0.8rem;
                                        font-weight: 400;
                                        line-height: 1.2;
                                        color: #564d4d;
                                        margin: 0;
                                    }
                                }
    
                                .form-group {
                                    display: flex;
                                    flex-direction: column;
    
                                    margin: 0 0 2rem;
    
                                    label {
                                        display: block;
                                        margin: 0 0 0.5rem;
                                    }
                                }
                            }
    
                            #{$dashboard}__customerViewPoints {
                                display: flex;
                                flex-direction: column;
    
                                .download_pdf_btn {
                                    display: flex;
                                    justify-content: end;
                                    padding-right: 2rem;
                                }
    
                                #{$dashboard}__customerViewHeading {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
    
                                    margin: 0 0 1rem;
                                    h3 {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        flex-direction: column;
                                        text-align: center;
    
                                        font-size: 16px;
                                        font-weight: 400;
                                        line-height: 1.2;
                                        color: #282828;
                                        margin: 0;
    
    
                                        .totalReward {
                                            font-weight: 700;
                                            color: $primary-color;
                                            margin-left: 0.5rem;
                                        }
                                    }
    
                                    button {
                                        padding: 0.4rem 2.5rem;
                                    }
                                }
    
                                #{$dashboard}__campaignReportMainBox {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
    
                                    @media only screen and (max-width: $mobileBig) {
                                        flex-wrap: wrap;
                                    }
    
                                    #{$dashboard}__campaignReport {
                                        width: calc(100% / 4 - 15px);
                                        margin-right: 15px;
                                        padding: 3.5rem 1.5rem 1.5rem;
                                        border-radius: 1rem;
                                        position: relative;
                                        align-self: stretch;
                                        flex-direction: row;
                                        display: flex;
                                        align-items: flex-end;
                                        flex-wrap: wrap;
                                        overflow: hidden;
                                        background: hsla(206, 100%, 92%, 1);
                                        background: linear-gradient(90deg, hsla(206, 100%, 92%, 1) 0%, hsla(206, 100%, 92%, 1) 16%, hsla(0, 0%, 100%, 1) 100%);
                                        background: -moz-linear-gradient(90deg, hsla(206, 100%, 92%, 1) 0%, hsla(206, 100%, 92%, 1) 16%, hsla(0, 0%, 100%, 1) 100%);
                                        background: -webkit-linear-gradient(90deg, hsla(206, 100%, 92%, 1) 0%, hsla(206, 100%, 92%, 1) 16%, hsla(0, 0%, 100%, 1) 100%);
                                        border: 1px solid hsla(206, 100%, 92%, 1);
    
                                        &:last-child {
                                            margin-right: 0;
                                        }
    
                                        &:before {
                                            content: "";
                                            width: 140px;
                                            height: 140px;
                                            border-radius: 100%;
                                            position: absolute;
                                            right: -40px;
                                            bottom: -40px;
                                            opacity: 0.5;
                                            background-color: transparent;
                                            z-index: 0;
                                            border: 1px solid hsl(205deg 100% 84.52%);
                                        }
    
                                        &:after {
                                            content: "";
                                            width: 100px;
                                            height: 100px;
                                            border-radius: 100%;
                                            position: absolute;
                                            right: -30px;
                                            bottom: -30px;
                                            opacity: 0.5;
                                            background-color: hsl(205deg 100% 84.52%);
                                        }
    
                                        &:hover{
                                            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                                        }
    
                                        // &:nth-child(1) {
                                        //     background-color: #003249;
    
                                        //     &:before {
                                        //         border: 1px solid #80ced7;
                                        //     }
    
                                        //     &:after {
                                        //         background-color: #80ced7;
                                        //     }
                                        // }
                                        // &:nth-child(2) {
                                        //     background-color: #BEADFA;
    
                                        //     &:before {
                                        //         border: 1px solid #BC7AF9;
                                        //     }
    
                                        //     &:after {
                                        //         background-color: #BC7AF9;
                                        //     }
                                        // }
                                        // &:nth-child(3) {
                                        //     background-color: #2056a5;
    
                                        //     &:before {
                                        //         border: 1px solid #4091f8;
                                        //     }
    
                                        //     &:after {
                                        //         background-color: #4091f8;
                                        //     }
                                        // }
                                        // &:nth-child(4) {
                                        //     background-color: #1FAB89;
    
                                        //     &:before {
                                        //         border: 1px solid #9DF3C4;
                                        //     }
    
                                        //     &:after {
                                        //         background-color: #9DF3C4;
                                        //     }
                                        // }
    
                                        .campaignReportTextBox {
                                            h3 {
                                                font-size: 1.5rem;
                                                color: $color-black;
                                                margin-bottom: 0;
                                                font-weight: 700;
                                            }
    
                                            h4 {
                                                font-size: 1rem;
                                                color: $color-black;
                                                margin-bottom: 0.5rem;
                                            }
                                        }
    
                                        @media only screen and (max-width: $mobileBig) {
                                            width: calc(100%);
                                            margin-right: 0;
                                            margin-bottom: 15px;
    
                                            &:last-child {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                }
                                #{$dashboard}__campaignReportMainBoxx {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    margin-top: 20px;
    
                                    @media only screen and (max-width: $mobileBig) {
                                        flex-wrap: wrap;
                                    }
    
                                    #{$dashboard}__campaignReport {
                                        width: calc(100% / 4 - 15px);
                                        margin-right: 15px;
                                        padding: 3.5rem 1.5rem 1.5rem;
                                        border-radius: 1rem;
                                        position: relative;
                                        align-self: stretch;
                                        flex-direction: row;
                                        display: flex;
                                        align-items: flex-end;
                                        flex-wrap: wrap;
                                        overflow: hidden;
                                        background: hsla(206, 100%, 92%, 1);
                                        background: linear-gradient(90deg, hsla(206, 100%, 92%, 1) 0%, hsla(206, 100%, 92%, 1) 16%, hsla(0, 0%, 100%, 1) 100%);
                                        background: -moz-linear-gradient(90deg, hsla(206, 100%, 92%, 1) 0%, hsla(206, 100%, 92%, 1) 16%, hsla(0, 0%, 100%, 1) 100%);
                                        background: -webkit-linear-gradient(90deg, hsla(206, 100%, 92%, 1) 0%, hsla(206, 100%, 92%, 1) 16%, hsla(0, 0%, 100%, 1) 100%);
                                        border: 1px solid hsla(206, 100%, 92%, 1);
    
                                        &:last-child {
                                            margin-right: 0;
                                        }
    
                                        &:before {
                                            content: "";
                                            width: 140px;
                                            height: 140px;
                                            border-radius: 100%;
                                            position: absolute;
                                            right: -40px;
                                            bottom: -40px;
                                            opacity: 0.5;
                                            background-color: transparent;
                                            z-index: 0;
                                            border: 1px solid hsl(205deg 100% 84.52%);
                                        }
    
                                        &:after {
                                            content: "";
                                            width: 100px;
                                            height: 100px;
                                            border-radius: 100%;
                                            position: absolute;
                                            right: -30px;
                                            bottom: -30px;
                                            opacity: 0.5;
                                            background-color: hsl(205deg 100% 84.52%);
                                        }
    
                                        &:hover{
                                            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                                        }
    
                                        // &:nth-child(1) {
                                        //     background-color: #003249;
    
                                        //     &:before {
                                        //         border: 1px solid #80ced7;
                                        //     }
    
                                        //     &:after {
                                        //         background-color: #80ced7;
                                        //     }
                                        // }
                                        // &:nth-child(2) {
                                        //     background-color: #BEADFA;
    
                                        //     &:before {
                                        //         border: 1px solid #BC7AF9;
                                        //     }
    
                                        //     &:after {
                                        //         background-color: #BC7AF9;
                                        //     }
                                        // }
                                        // &:nth-child(3) {
                                        //     background-color: #2056a5;
    
                                        //     &:before {
                                        //         border: 1px solid #4091f8;
                                        //     }
    
                                        //     &:after {
                                        //         background-color: #4091f8;
                                        //     }
                                        // }
                                        // &:nth-child(4) {
                                        //     background-color: #1FAB89;
    
                                        //     &:before {
                                        //         border: 1px solid #9DF3C4;
                                        //     }
    
                                        //     &:after {
                                        //         background-color: #9DF3C4;
                                        //     }
                                        // }
    
                                        .campaignReportTextBox {
                                            h3 {
                                                font-size: 1.5rem;
                                                color: $color-black;
                                                margin-bottom: 0;
                                                font-weight: 700;
                                            }
    
                                            h4 {
                                                font-size: 1rem;
                                                color: $color-black;
                                                margin-bottom: 0.5rem;
                                            }
                                        }
    
                                        @media only screen and (max-width: $mobileBig) {
                                            width: calc(100%);
                                            margin-right: 0;
                                            margin-bottom: 15px;
    
                                            &:last-child {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                }
    
                                #{$dashboard}__campaignReportMainBox_2 {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex-wrap: wrap;
    
                                    #{$dashboard}__campaignReport {
                                        width: calc(100%);
                                        margin: 0;
                                        padding: 3rem 1.5rem 1.5rem;
                                        border-radius: 1rem;
                                        position: relative;
                                        align-self: stretch;
                                        flex-direction: row;
                                        display: flex;
                                        align-items: flex-end;
                                        flex-wrap: wrap;
                                        overflow: hidden;
                                        margin-bottom: 15px;
    
                                        &:last-child {
                                            margin-bottom: 0;
                                        }
    
                                        &:before {
                                            content: "";
                                            width: 140px;
                                            height: 140px;
                                            border-radius: 100%;
                                            position: absolute;
                                            right: -40px;
                                            bottom: -40px;
                                            opacity: 0.5;
                                            background-color: transparent;
                                            z-index: 0;
                                        }
    
                                        &:after {
                                            content: "";
                                            width: 100px;
                                            height: 100px;
                                            border-radius: 100%;
                                            position: absolute;
                                            right: -30px;
                                            bottom: -30px;
                                            opacity: 0.5;
                                        }
    
                                        @media only screen and ($mobile) {
                                            width: calc(100%);
                                            margin-right: 0;
                                        }
    
                                        &:nth-child(1) {
                                            background-color: #1b1b1b;
    
                                            &:before {
                                                border: 1px solid #5f5e5e;
                                            }
    
                                            &:after {
                                                background-color: #5f5e5e;
                                            }
                                        }
                                        &:nth-child(2) {
                                            background-color: #9966cc;
    
                                            &:before {
                                                border: 1px solid #c496f1;
                                            }
    
                                            &:after {
                                                background-color: #c496f1;
                                            }
                                        }
                                        &:nth-child(3) {
                                            background-color: #0070ff;
    
                                            &:before {
                                                border: 1px solid #4091f8;
                                            }
    
                                            &:after {
                                                background-color: #4091f8;
                                            }
                                        }
                                        &:nth-child(4) {
                                            background-color: #ed9121;
    
                                            &:before {
                                                border: 1px solid #ffb863;
                                            }
    
                                            &:after {
                                                background-color: #ffb863;
                                            }
                                        }
    
                                        .campaignReportTextBox {
                                            h3 {
                                                font-size: 1.5rem;
                                                color: $color-white;
                                                margin-bottom: 0;
                                                font-weight: 700;
                                            }
    
                                            h4 {
                                                font-size: 1rem;
                                                color: $color-white;
                                                margin-bottom: 0.5rem;
                                            }
                                        }
                                    }
                                }
    
                                #{$dashboard}__customerViewBox {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    flex-direction: column;
    
                                    background-color: #f3f3f3;
                                    padding: 1.5rem 0.8rem;
                                    border-radius: 0.375rem;
                                    margin: 0 0 1rem;
    
                                    h4 {
                                        font-size: 0.8rem;
                                        font-weight: 400;
                                        line-height: 1.2;
                                        color: #282828;
                                        margin: 0 0 0.5rem;
                                        text-align: center;
                                    }
    
                                    h2 {
                                        font-size: 1.2rem;
                                        font-weight: 800;
                                        line-height: 1.2;
                                        color: $primary-color;
                                        margin: 0;
                                        span {
                                            &.totalReward {
                                                font-weight: 700;
                                                background: $primary-color;
                                                color: white;
                                                padding: 5px 10px;
                                                border-radius: 5px;
                                                display: inline-flex;
                                                align-items: center;
                                                font-size: 16px;
                                                cursor: pointer;
                                            }
                                            &.line {
                                                margin-left: 5px;
                                                margin-right: 5px;
                                                color: rgba(#fff, 0.2);
                                                font-weight: 500;
                                            }
                                        }
                                    }
                                }
    
                                .form-group {
                                    display: flex;
                                    flex-direction: column;
    
                                    margin: 0 0 2rem;
    
                                    label {
                                        display: block;
                                        margin: 0 0 0.5rem;
                                    }
                                }
                            }
                        }
                    }
                }
                #{$dashboard}__notFoundBox {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
    
                    figure {
                        display: flex;
                        align-items: center;
                        justify-content: center;
    
                        margin: 0 0 1.5rem;
    
                        img {
                            width: 40%;
                        }
                    }
    
                    h3 {
                        font-size: 1.2rem;
                        font-weight: 600;
                        line-height: 1.5;
                        color: $color-black;
                        margin: 0 0 1rem;
    
                        // @media (max-width: $mobileBig) {
                        // 	font-size: 1rem;
                        // }
                    }
    
                    a {
                    }
                }
    
                #{$dashboard}__notAccessible {
                    display: flex;
                    justify-content: center;
    
                    .notAccessibleBox {
                        width: 50%;
                        padding: 2rem;
                        background-color: #fff;
                        border-radius: 1rem;
                        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
    
                        figure {
                            display: flex;
                            justify-content: center;
                        }
    
                        h3 {
                            font-size: 1.2rem;
                            font-weight: 600;
                            line-height: 1.5;
                            color: $color-black;
                            margin: 0 0 1rem;
                            text-align: center;
    
                            // @media (max-width: $mobileBig) {
                            // 	font-size: 1rem;
                            // }
                        }
    
                        .plan_buttons {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-wrap: wrap;
    
                            div {
                                width: calc(50% - 10px);
                                margin-right: 10px;
                                margin-bottom: 10px;
    
                                &:nth-child(2n) {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
    
                #{$dashboard}__plansMainBox {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 1.5rem;
                    flex-wrap: wrap;
    
                    @media only screen and (max-width: 768px) {
                        flex-wrap: wrap;
                    }
    
                    .PlansBox {
                        padding: 2.5rem 1rem;
                        background-color: #fff;
                        border-radius: 1rem;
                        width: calc(25% - 20px);
                        margin-right: 20px;
                        border: 1px solid #000;
                        text-align: center;
                        margin-bottom: 1rem;
    
                        @media only screen and (max-width: 768px) {
                            width: 100%;
                            margin-right: 0;
                            margin-bottom: 1rem;
                        }
    
                        &:hover {
                            border-color: #2056a5;
                        }
    
                        &:first-child {
                            background-color: #2056a5;
                            color: #fff;
                            border-color: #2056a5;
    
                            a {
                                color: #000;
                                padding: 0.3rem 0.8rem;
                                background-color: #fff;
                                margin-top: 0.5rem;
                                display: inline-block;
                                border-radius: 0.25rem;
                                font-weight: 500;
                            }
                        }
    
                        &:nth-child(4n) {
                            margin-right: 0;
                        }
    
                        h4 {
                            font-weight: 600;
                        }
    
                        a {
                            background-color: #2056a5;
                            color: #fff;
                            padding: 0.3rem 0.8rem;
                            margin-top: 0.5rem;
                            display: inline-block;
                            border-radius: 0.25rem;
                            font-weight: 500;
                        }
                    }
                }
    
                #{$dashboard}__currentPlanMainBox {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 1.5rem;
    
                    @media only screen and (max-width: 768px) {
                        flex-wrap: wrap;
                    }
    
                    .currentPlanNewBox {
                        padding: 1.5rem;
                        background-color: #fff;
                        border-radius: 1rem;
                        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                        margin-bottom: 2rem;
    
                        p {
                            margin-bottom: 0;
    
                            span {
                                color: #ff0800;
                            }
                        }
                    }
                }
    
                #{$dashboard}__plansNewMainBox {
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 1.5rem;
                    margin-top: 1.5rem;
                    justify-content: space-between;
                    //width: 75%;
                    width: 100%;
                    margin: 0 auto;
    
                    @media only screen and (max-width: 768px) {
                        flex-wrap: wrap;
                        width: 100%;
                    }
    
                    .plansNewBox {
                        width: calc(50% - 15px);
                        margin-right: 15px;
                        padding: 1.5rem;
                        //background-color: #fff;
                        align-self: stretch;
                        position: relative;
                        border: 1px solid gray;
                        background-color: #ebf4fe;
                        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
    
                        @media only screen and (max-width: 768px) {
                            width: calc(100%);
                            margin-right: 0;
                            margin-bottom: 1.5rem;
                        }
    
                        &:last-child {
                            margin-right: 0;
                        }
    
                        &:hover,
                        &.mostpopular {
                            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                            border-color: #255bca;
                        }
    
                        .current_plan_title {
                            display: flex;
                            width: calc(100% + 2px);
                            padding: 0.8rem;
                            background-color: #255bca;
                            color: #fff;
                            position: absolute;
                            left: -1px;
                            text-align: center;
                            justify-content: center;
                            top: -50px;
                            font-weight: 700;
                            border-top-left-radius: 10px;
                            border-top-right-radius: 10px;
                            font-size: 16px;
                        }
    
                        .mpstripbox {
                            position: absolute;
                            top: -15px;
                            left: -2px;
    
                            span {
                                display: inline-block;
                                position: relative;
                                background-color: #ff9900;
                                text-align: center;
                                font-size: 12px;
                                padding: 5px 15px;
                                color: #fff;
                                border-radius: 0;
                                margin-left: 0;
                                margin-top: 0;
                                border-bottom-right-radius: 5px;
                                border-top-right-radius: 5px;
                                font-weight: 400;
                            }
                        }
    
                        .plansInnerBox {
                            text-align: center;
                            height: calc(100% - 50px);
    
                            h3 {
                                font-size: 36px;
                                color: #0d63be;
                                font-weight: 900;
                            }
    
                            h4 {
                                color: #000;
                                font-weight: 800;
                                font-size: 32px;
    
                                span {
                                    font-weight: 300;
                                    font-size: 16px;
    
                                    &.old_price{
                                        font-size: 20px;
                                        color: #282828;
                                        text-decoration: line-through !important;
                                        margin-right: 4px;
                                    }
                                }
                            }
    
                            h5 {
                                font-size: 16px;
                                font-weight: 600;
                                margin-bottom: 15px;
                            }
    
                            ul {
                                padding-left: 0;
                                -moz-column-count: 2;
                                -moz-column-gap: 20px;
                                -webkit-column-count: 2;
                                -webkit-column-gap: 20px;
                                column-count: 2;
                                column-gap: 20px;
    
                                @media only screen and (max-width: 600px) {
                                    column-count: 1;
                                }
    
    
                                li {
                                    list-style: none;
                                    text-align: left;
                                    padding: 5px 0 5px 30px;
                                    font-size: 15px;
                                    position: relative;
                                    font-weight: 500;
    
                                    @media only screen and (max-width: 600px) {
                                        font-size: 12px;
                                    }
    
                                    &:before {
                                        content: "";
                                        display: flex;
                                        width: 8px;
                                        height: 15px;
                                        border: none;
                                        border-right: 1px solid #0ea015;
                                        border-bottom: 1px solid #0ea015;
                                        transform: rotate(45deg);
                                        position: absolute;
                                        left: 4px;
                                        align-items: center;
                                        top: 6px;
                                    }
                                }
                            }
    
                            p{
                                font-size: 16px;
                                font-weight: 600;
                                color: #006bc0;
                                padding-bottom: 15px;
                                border-bottom: 1px solid #ccc;
                            }
                        }
    
                        .plan_select_btn {
                            @media only screen and (max-width: 768px) {
                                display: flex;
                                margin-top: 1.5rem;
                            }
    
                            a {
                                @media only screen and (max-width: 768px) {
                                    width: 100%;
                                }
                            }
    
                            button {
                                display: flex;
                                width: 100%;
                                text-align: center;
                                justify-content: center;
                                padding: 0.5rem;
                                background-color: #255bca;
                                font-weight: 600;
                                color: #fff;
                                border: none;
                                border-radius: 4px;
                            }
                        }
    
                        &.current_plan {
                            @media only screen and (max-width: 768px) {
                                margin-top: 2.5rem;
                            }
    
                            .current_plan_title {
                                @media only screen and (max-width: 768px) {
                                    top: -33px;
                                }
                            }
                            .plansInnerBox {
                            }
                            .plan_select_btn {
                                button {
                                    background-color: gray;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.openSidebar {
            #{$dashboard}_dark__sidebar {
                width: 13rem;
                overflow: auto;
    
                #{$dashboard}__topSection {
                    padding: 0.75rem 1rem;
    
                    #{$dashboard}__logoBox {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
    
                    #{$dashboard}__menuBox {
                        span {
                            img {
                                &.inside {
                                    display: block;
                                }
    
                                &.outside {
                                    display: none;
                                }
                            }
                        }
                    }
                }
    
                #{$dashboard}__menuMain {
                    #{$dashboard}__menuTop,
                    #{$dashboard}__menuBottom {
                        ul {
                            padding: 0.375rem 0;
    
                            li {
                                display: flex;
                                align-items: normal;
                                flex-direction: column;
    
                                padding: 0 0.75rem 0.375rem;
    
                                &:last-child {
                                    padding-bottom: 0;
                                }
    
                                .link {
                                    justify-content: flex-start;
                                    padding: 0.5rem 0.75rem;
                                    position: relative;
    
                                    .link_text {
                                        display: block;
                                    }
    
                                    .link_icon{
                                        display: flex;
                                    }
                                }
    
                                .childrenDropdown {
                                    position: static;
                                    padding: 0;
                                    padding-left: 1.5rem;
                                    margin: 0.5rem 0 0;
                                    width: 100%;
                                    background-color: transparent;
                                    transform: translateY(0);
    
                                    li {
                                        padding: 0 0 0.5rem !important;
                                        margin-bottom: 0.5rem;
    
                                        &:last-child {
                                            padding: 0 !important;
                                            margin-bottom: 0;
                                        }
    
                                        button.selected {
                                            background: #d9eeff;
                                            color: #2056a5;
                                            font-weight: 600;
                                        }
                                    }
    
                                    ul {
                                        position: static;
                                        padding: 0;
                                        padding-left: 1rem;
                                        margin: 0 0 0;
                                        width: 100%;
                                        background-color: transparent;
                                        transform: translateY(0);
                                        padding-bottom: 1rem;
    
                                        li {
                                            a {
                                                font-size: 0.7rem;
                                            }
                                        }
                                    }
                                }
    
                                .pro_options {
                                    position: relative;
                                    display: flex;
                                    align-items: center;
    
                                    &:before {
                                        content: "Pro";
                                        position: absolute;
                                        right: 6px;
                                        padding: 0px;
                                        background-color: #f90;
                                        color: #fff;
                                        font-size: 7px;
                                        margin: 0;
                                        border-radius: 4px;
                                        width: 20px;
                                        height: 15px;
                                        text-align: center;
                                        line-height: 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
    
            #{$dashboard}_dark__mainPage {
                width: calc(100% - 13rem);
                margin-left: 13rem;
    
                @media (max-width: $mobileBig) {
                    width: calc(100% - 3.5rem);
                    margin-left: 3.5rem;
                }
    
                #{$dashboard}__headerSection {
                    width: calc(100% - 13rem);
                    left: 13rem;
    
                    @media (max-width: $mobileBig) {
                        width: calc(100% - 3.5rem);
                        left: 3.5rem;
                    }
                }
            }
        }
    }

    

    

    
}

div.btn-container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

div.btn-container i {
    display: inline-block;
    position: relative;
    top: -9px;
}

label {
    font-size: 13px;
    color: #424242;
    font-weight: 500;
}

.btn-color-mode-switch {
    display: inline-block;
    margin: 0px;
    position: relative;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner {
    margin: 0px;
    width: 140px;
    height: 30px;
    background: #e0e0e0;
    border-radius: 26px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
    display: block;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
    content: attr(data-on);
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    top: 7px;
    right: 20px;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
    content: attr(data-off);
    width: 70px;
    height: 30px;
    background: #fff;
    border-radius: 26px;
    position: absolute;
    left: 2px;
    top: 0px;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 6px -2px #111;
    padding: 5px 0px;
}

.btn-color-mode-switch > .alert {
    display: none;
    background: #ff9800;
    border: none;
    color: #fff;
}

.btn-color-mode-switch input[type="checkbox"] {
    cursor: pointer;
    width: 50px;
    height: 25px;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 0px;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner {
    background: #151515;
    color: #fff;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after {
    content: attr(data-on);
    left: 68px;
    background: #3c3c3c;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before {
    content: attr(data-off);
    right: auto;
    left: 20px;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner {
    /*background: #66BB6A; */
    /*color: #fff;*/
}

.btn-color-mode-switch input[type="checkbox"]:checked ~ .alert {
    display: block;
}

/*mode preview*/
.dark-preview {
    background: #0d0d0d;
}

.dark-preview div.btn-container i.fa-sun-o {
    color: #777;
}

.dark-preview div.btn-container i.fa-moon-o {
    color: #fff;
    text-shadow: 0px 0px 11px #fff;
}

.white-preview {
    background: #fff;
}

.white-preview div.btn-container i.fa-sun-o {
    color: #ffa500;
    text-shadow: 0px 0px 16px #ffa500;
}

.white-preview div.btn-container i.fa-moon-o {
    color: #777;
}

.dark-preview p.by a {
    color: #777;
}

.white-preview p.by a {
    color: #000;
}

.toggle {
    --width: 100px;
    --height: calc(var(--width) / 3);

    position: relative;
    display: inline-block;
    width: var(--width);
    height: var(--height);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: var(--height);
    cursor: pointer;
}

.toggle input {
    display: none;
}

.toggle .slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--height);
    background-color: #ccc;
    transition: all 0.4s ease-in-out;
}

.toggle .slider::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    // width: calc(var(--height));
    // height: calc(var(--height));
    width: 24px;
    height: 24px;
    border-radius: calc(var(--height) / 2);
    background-color: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    transition: all 0.4s ease-in-out;
}

.toggle input:checked + .slider {
    background-color: #50bc14;
}

.toggle input:checked + .slider::before {
    transform: translateX(calc(var(--width) - var(--height)));
}

.toggle .labels {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 10px;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
    text-transform: capitalize;
}

.toggle .labels::after {
    content: attr(data-off);
    position: absolute;
    right: 8px;
    color: #000;
    opacity: 1;
    // text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    transition: all 0.4s ease-in-out;
    font-weight: 600;
}

.toggle .labels::before {
    content: attr(data-on);
    position: absolute;
    left: calc(var(--height) - var(--width) + 8px);
    color: #ffffff;
    opacity: 0;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
    transition: all 0.4s ease-in-out;
    // font-size: larger;
}

.toggle input:checked ~ .labels::after {
    opacity: 0;
    transform: translateX(calc(var(--width) - var(--height)));
    font-size: larger;
}

.toggle input:checked ~ .labels::before {
    opacity: 1;
    transform: translateX(calc(var(--width) - var(--height)));
}

.pp_accordian .accordion-button:not(.collapsed) {
    color: #000;
    font-weight: 600;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
}

a{
    &.close_this_box{
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 20px;
        color: #282828;
        font-weight: 600;
    }
}

.Toastify__toast {
    background: #f9f9c9 !important;
}
.theme-selection{
    padding: 5px;
}
.datas{
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
}
.themeData{
    vertical-align: top;
    margin-right: 10px;
    padding: 10px;
}

.preview {
    border: 1px solid #000000;
    border-radius: 4px;
    width: 100%;
    height: 260px;
    padding: 5px;
}
.themeData {
    border: 0;
    display: inline-block;
    padding: 12px 24px;
    font-size: 14px;
    border-radius: 4px;
    margin-top: 5px;
    width: 100%;
    cursor: pointer;
}



// .row {
//     margin-left: -10px;
//     margin-right: -10px;
//     &.gx-2 {
//         [class*=col-] {
//             padding-left: 10px;
//             padding-right: 10px;
//         }
//     }
// }

.color-align {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.enhance-block {
    text-align: center;
    margin-bottom: 20px;
    p {
        font-size: 15px !important;
    }
}


.integration-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    .integration {
        width: calc(100% / 3 - 15px);
        border: #f1f1f1 solid 1px;
        background-color: #fff;
        padding: 30px;
        border-radius: 10px;
        text-align: center;

        p {
            font-size: 16px;
        }

        a {
            height: 120px;
            display: inline-flex;
            align-items: center;
        }

        h4 {
            height: 120px;
            display: inline-flex;
            align-items: center;
            color: #252525;
            font-size: 16px;
            font-weight: 700;
        }
    }
}
#cke_1_contents {
    min-height: 300px !important;
}


.ml-2 {
    margin-left: 5px;
}



.ruleGroup-addGroup {
    display: none;
}

.newStyleBlock {
    background-color: #f1f1f1;
    margin: 10px 0 0 0;
    border-radius: 10px;
    h4 {
        font-size: 16px;
        color: #5e5e5e;
        border-bottom: #949494 solid 1px;
        padding: 10px;
        font-weight: 700;
    }
}
.newStyleList {
    list-style: none;
    
    padding: 0;
    
    li {
        padding: 10px 15px;
        width: 50%;
        display: inline-block;
        &:not(:last-child) {
            border-bottom: #ddd solid 1px;
        }

        span {
            width: 150px;
            display: inline-block;
            font-weight: 600;
        }
    }
}

.allDropdown {
    width: 200px;
}


.dashboard {
    &__transactionsModal {
        .dataLoop {
            &__row {
                display: flex;
                border-bottom: #c4c4c4 solid 1px;
                align-items: center;
                padding: 0 20px;
                p {
                    margin: 0;
                    padding: 8px 0;
                }
                &__left {
                    width: 30%;
                    p {
                        text-align: left;
                    }
                }
                &__right {
                    width: 70%;
                    p {
                        text-align: left;
                    }
                }
            }
        }
    }
}

 
 
.reportClass {
    width: 100%; 
    height: 80vh; 
}


.modal {
    &.imgModalUi {
        .modal-dialog {
            .modal-content {
                .modal-header {
                    padding: 10px 15px !important;
                    .modal-title {
                        font-size: 16px;
                    }
                    .close {
                        border: none;
                        outline: none;
                        background: none;
                        color: white;
                        font-size: 20px;
                    }
                }
                .modal-body {
                    .uploadedImgView {
                        height: 200px;
                        overflow: hidden;
                        background-color: #f1f1f1;
                        border-radius: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img {
                            max-height: 100%;
                        }
                    }
                    .reginBtn {
                        height: 38px;
                    }
                }
                .modal-footer {

                }
            }
        }
    }
}

.btn38 {
    height: 38px;
}
.helpfaq {
    color: #0d6efd;
    font-size: medium;
}