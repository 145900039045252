$mobileBig: 767px;
$color-black: #000;

.dashboard.openSidebar{
    .dashboard__mainPage{
        .dashboard__content{
            .giftcard_mainbox{
                .gc_contentbox{
                    margin-left: 25%;
                }
            }
        }
    }
}

.gc_content {
    margin-bottom: 20px;
    color: #000;
}

.signUpPage__formSection {
    .form-group {
        label {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 0.5rem;
        }
    }
}

.campaignSelection__boxes {
    width: 100%;
    padding: 0 2rem;

    @media screen and (max-width: $mobileBig) {
        padding: 1.5rem;
    }

    .giftcard_voucher_box {
        border-radius: 0.5rem;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        border: 1px solid rgba(0,0,0, 0.35);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        &.selected_voucher{

            .giftcard_voucher_details{
                background-color: #2056a5;

                .giftcard_voucher_title{

                    h5{color: #FFF;}

                }
                
            }
        }

        .gc_imagebox {
            position: relative;
            height: 150px;
            display: inline-flex;
            align-items: center;
            max-height: 150px;
            width: 100%;

            img {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                max-height: 150px;
                width: 100%;
            }

            .giftcard_voucher_selected{
                position: absolute;
                width: 20px;
                height: 20px;
                background-color: #2056a5;
                border-radius: 10px;
                top: 8px;
                right: 8px;

                &:before{
                    content: "";
                    width: 5px;
                    height: 10px;
                    position: absolute;
                    border-right: 1px solid #fff;
                    border-bottom: 1px solid #FFF;
                    right: 7px;
                    transform: rotate(45deg);
                    top: 4px;
                }
            }

            

            .gc_overlay {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.62);
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                display: none;

                .gc_overlay_box {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    ul {
                        padding: 0;
                        margin: 0;
                        text-align: center;

                        li {
                            list-style: none;

                            display: block;

                            margin-bottom: 5px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            a {
                                color: #000;

                                font-size: 12px;

                                padding: 5px;

                                border-radius: 4px;

                                background-color: #e3f2fd;

                                font-weight: 600;

                                width: 100px;

                                display: block;

                                text-align: center;

                                border: 1px solid #e3f2fd;

                                &:hover {
                                    background-color: #0e3979;
                                    color: #FFF;
                                    border-color: #0e3979;
                                }
                            }
                        }
                    }
                }
            }
        }

        .giftcard_voucher_details {
            margin: 0;
            align-items: center;
            padding: 10px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            display: flex;
            justify-content: space-between;
            background-color: #e3f2fd;
            width: 100%;

            .giftcard_voucher_title {
                display: block;

                width: calc(100%);

                padding: 0;

                color: #000;

                text-align: center;

                h5 {
                    font-weight: 600;

                    color: #000;                    

                    margin-bottom: 0;

                    font-size: 13px;

                    overflow: hidden;

                    white-space: pre;

                    text-overflow: ellipsis;

                    @media screen and (max-width: $mobileBig) {
                        overflow: hidden;

                        white-space: pre;

                        text-overflow: ellipsis;
                    }
                }
            }

            .giftcard_voucher_button {
                display: flex;

                width: calc(30% - 5px);

                padding: 0;

                justify-content: end;

                .form-check {
                    min-height: 0;

                    padding-left: 0;

                    margin-bottom: 0;
                }

                button {
                    font-size: 10px;

                    padding: 2px 10px;

                    border-radius: 4px;
                }
            }
        }

        &:hover .gc_overlay {
            display: block;
        }
    }
}

.no_giftcard_box {
    text-align: center;

    img {
        margin-bottom: 1rem;
    }

    h6 {
        color: #000;
        margin-bottom: 0.8rem;
    }

    a {
        border-radius: 1rem;
    }
}

.gc_modal_box {
    .gc_title {
        margin-bottom: 1rem;

        h5 {
            color: #000;
            display: flex;
            align-items: center;
            margin-bottom: 0;

            span {
                color: #2056a5;
                margin-left: 5px;
            }
        }
    }

    .gc_amount {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        h5 {
            color: #000;
            margin-bottom: 0;
        }

        span.gc_amount_box {
            margin-left: 10px;

            span.gc_amount_single {
                padding: 5px 8px;
                margin-right: 5px;
                border: 1px solid #ccc;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                border-radius: 4px;
                color: #2056a5;
                font-weight: 500;
            }
        }
    }
    h5 {
        color: #000;
        display: flex;
        align-items: center;

        span {
            span {
                span {
                    padding: 5px;
                    border: 1px solid #ccc;
                    pointer-events: none;
                    font-size: 12px;
                }
            }
        }
    }

    ul {
        border: none !important;

        li {
            width: 25% !important;
            padding: 0.8rem;

            &:nth-child(1) {
                padding-left: 0.8rem;
            }
        }
    }
}
.giftcard_mainbox {
    display: flex;
    align-items: start;
    width: 100%;
    overflow: hidden;

    @media only screen and (max-width: $mobileBig) {
        flex-direction: column;
    }

    .gc_sidebar {
        width: 20%;
        height: calc(100vh - 120px);
        padding: 1rem;
        border-right: 1px solid rgba(0, 0, 0, 0.15);
        position: fixed;

        @media only screen and (max-width: $mobileBig){
            width: 100%;
            height: auto;
            overflow: hidden;
            position: relative;
            border-right: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            padding: 0;
        }

        button{
            &.open_country_box{

                padding: 0.5rem;
                background-color: transparent;
                border: none;
                display: flex;
                width: 100%;
                margin-bottom: 0.5rem;
                justify-content: space-between;
                align-items: center;

                h3{
                    margin-bottom: 0;
                    font-size: 1.4rem;
                }
            }

            &:after{
                width:  1.25rem;;
                height:  1.25rem;;
                content: "";
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                background-repeat: no-repeat;
                background-size: 1.25rem;
                transition: transform 0.2s ease-in-out;;
            }
        }

        .gc_sidebar_innerbox{
            
            button{
                padding: 0.25rem 0.5rem;
                border: none;
                background-color: transparent;
                font-size: 1.2rem;
                font-weight: 600;
                display: flex;
                width: 100%;
                margin-bottom: 0.5rem;
                align-items: center;

                &:after{
                    width: 1.25rem;
                    height: 1.25rem;
                    margin-right: 0.5rem;
                    content: "";
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                    background-repeat: no-repeat;
                    background-size: 1.25rem;
                    transition: transform 0.2s ease-in-out;
                    right: 8px;
                    position: absolute;
                }
            }

            .signUpPage__formSection{
            
                .country-select-form-box{
                    height: 100vh;
                    overflow-y: auto;
                    padding: 0.4rem;
                }
            }
        }

        h4 {
            margin-bottom: 1rem;
            position: relative;
            cursor: pointer;
            color: $color-black;
            font-weight: 600;
        }

        input[type="text"] {
            width: 100%;
            padding: 0.5rem;
            outline: none;
            border-radius: 0.25rem;
            // border: 1px solid #000;
            margin-bottom: 1rem;

            &:focus{
                border-color: #2056a5;
            }
        }

        .select_all_countries{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
            font-weight: 800;
            color: $color-black;
            position: relative;
        }

        ul{
            padding-left: 0;
            display: flex;
            justify-content: space-between;

            li{
                list-style: none;
                font-weight: 600;
                color: $color-black;
                text-overflow: ellipsis;
                white-space: pre;
                overflow: hidden;
                margin-right: 10px;
                margin-right: 10px;
            }
        }

        

        .country-select-form-box{

            overflow-y: auto;
            padding: 0 0.5rem;
            height: calc(100vh - 300px);

            @media only screen and (max-width: $mobileBig) {
                max-height: 100px;
                overflow-y: auto;
                padding: 0 0.5rem;
            }
        }
        
    }

    .form-group{

        input[type=checkbox]{
            width: 15px;
            height: 15px;
            margin: 0;

            @media only screen and (max-width: $mobileBig) {
                width: 10px;
                height: 10px;
            }
        }

    }

    .gc_contentbox {
        width: 80%;
        height: auto;
        padding: 1.5rem;
        margin-left: 25%;

        @media only screen and (max-width: $mobileBig) {
            width: 100%;
            margin-left: 0;
            padding: 0;
            padding-top: 0.8rem;
        }

        input[type=checkbox]{
            width: 15px;
            height: 15px;
            margin: 0;

            @media only screen and (max-width: $mobileBig) {
                width: 10px;
                height: 10px;
            }
        }

        .gc_content_pbox{
            width: 100%;

            input[type="text"] {
                width: 100%;
                padding: 0.5rem;
                outline: none;
                border-radius: 0.25rem;
                // border: 1px solid #000;
                margin-bottom: 1rem;
    
                &:focus{
                    border-color: #2056a5;
                }
            }

            .select_all_giftcard{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0.8rem;
                font-weight: 600;
                color: $color-black;
                padding: 0 20px;
                padding: 0 20px;
            }

            .accordion-item{

                margin-bottom: 1rem;

                .accordion-header{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .accordion-button{

                            background-color: #FFF;
                            color: #000;
                            font-weight: 600;

                        &.collapsed{
                            font-weight: 400;   
                        }

                        &:before{
                            width: var(--bs-accordion-btn-icon-width);
                            height: var(--bs-accordion-btn-icon-width);
                            margin-right: 0.5rem;
                            content: "";
                            background-image: var(--bs-accordion-btn-icon);
                            background-repeat: no-repeat;
                            background-size: var(--bs-accordion-btn-icon-width);
                            transition: var(--bs-accordion-btn-icon-transition);
                        }

                        &:after{
                            content: none;
                        }
                    }

                    

                    input{
                        margin-right: 1rem;
                    }
                }

                .accordion-body{
                    display: flex;
                    flex-wrap: wrap;

                    .campaignSelection__boxes{
                        padding: 0;
                    }

                    .gc_inmainbox{
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        flex-wrap: wrap;


                        .gc_innbox{
                            width: calc(20% - 10px);
                            margin-right: 10px;
                            display: flex;
                            margin-bottom: 20px;
                            flex-flow: column;

                            @media only screen and (max-width: $mobileBig){

                                width: calc(100%/2 - 10px );
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }

            .campaignSelection__boxes{
                padding: 0;

                .gc_inmainbox{
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    flex-wrap: wrap;

                    .gc_innbox{

                        width: calc(100%/5 - 10px);
                        margin-right: 10px;
                        display: flex;
                        margin-bottom: 20px;
                        flex-flow: column;

                        @media only screen and (max-width: $mobileBig){

                            width: calc(100%/2 - 10px );
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}


.countryLabel {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #000;
}